import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { get, post, put } from '../../../../api'
import { GET_TENANT, CREATE_TENANT, UPDATE_TENANT } from '../../../../api/endpoints'
import { FormField, SelectField, TextAreaField, DatePickerField, CurrencyField, } from '../../../../components/Forms/FormFields';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { concatUrlWithId, checkPermission, formatDateAndTime } from '../../../../utils/commonUtils';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
// import { UserPermissionStore } from '../../Store/UserPermission';

export const investmentSchema = Yup.object().shape({
    suite_number: Yup.string().label('Suite Number').trim().required('Suite Number  is required'),
    tenant_name: Yup.string().label('Tenant Name').trim().required('Tenant Name is required'),
    tenant_contact_name: Yup.string().label('Tenant Contact Name').trim().required('Tenant Contact Name is required'),
    tenant_contact_email: Yup.string().label('Tenant Contact Email').email('Invalid email address').matches(/^[^\s@.]+(\.[^\s@.]+)*@[^\s@.]+(\.[^\s@.]+)+$/, 'Invalid email address').required('Tenant Contact Email is required'),
    monthly_payment: Yup.string()
    .transform(value => (value === '' || value === 'NaN'  ? null : value))
    .nullable()
    .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.'),
    tenant_contact_number: Yup.string().label('Mobile Number').trim().nullable().matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid mobile number'),
    // lease_termination_date: Yup.date().label('Lease Termination Date'),
    // lease_start_date: Yup.date().label('Lease Start Date'),
    // notes: Yup.string().label('Notes').trim().required('Notes is required'),
});

function TenantModelForm({ show, handleClose, mode, editId, onSuccess, investmentId, keyValue, headerName, formAction }) {

    const [initialValues, setInitialValues] = useState({
        type: '',
        suite_number: '',
        tenant_name: '',
        tenant_contact_name: '',
        tenant_contact_number: '',
        investment_id: investmentId,
        tenant_contact_email: '',
        lease_start_date: '',
        lease_termination_date: '',
        rented_square_feet: '',
        monthly_payment: '',
        rent_increase_start_date: '',
        notes: '',
    });

    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [managers, setManagers] = useState([]);
    const [userPermissions, setUserPermissions] = useState("");
    // const { checkAndUpdatePermissions } = UserPermissionStore();

    function formatContactNumber(contactNumber) {
        if (!contactNumber) return '';
        const formattedValue = contactNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        return formattedValue;
    }

    const notify = (message, type) => toast(message, { type });
    const authCheckAndFetch = async () => {
        // let tempPermission = await checkAndUpdatePermissions();

        // setUserPermissions(tempPermission || []);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!mode) {
                setInitialValues({
                    type: '',
                    suite_number: '',
                    tenant_name: '',
                    tenant_contact_name: '',
                    tenant_contact_number: '',
                    investment_id: investmentId,
                    tenant_contact_email: '',
                    lease_start_date: '',
                    lease_termination_date: '',
                    rented_square_feet: '',
                    monthly_payment: '',
                    rent_increase_start_date: '',            
                    notes: '',
                });
                setEditMode(false);
                setViewMode(false);
            } else if (mode == 'view') {
                setViewMode(true);
            } else if (mode == 'edit') {
                setEditMode(true);
                setViewMode(false);
            } else {
                setEditMode(false);
                setViewMode(false);
            }

            if ((mode == 'view' || mode == 'edit')) {
                try {
                    const response = await get(concatUrlWithId(GET_TENANT, editId));
                    // Process the fetched data here
                    if (response) {
                        let values = {
                            id: response.id,
                            type: response.type,
                            suite_number: response.suite_number,
                            tenant_name: response.tenant_name,
                            tenant_contact_name: response.tenant_contact_name,
                            tenant_contact_number: response.tenant_contact_number,
                            investment_id: response.investment_id,
                            tenant_contact_email: response.tenant_contact_email,
                            lease_start_date: formatDateAndTime(response.lease_start_date, 'YYYY-MM-DD'),
                            lease_termination_date: formatDateAndTime(response.lease_termination_date, 'YYYY-MM-DD'),
                            rented_square_feet: response.rented_square_feet,
                            monthly_payment: response.monthly_payment,
                            rent_increase_start_date: formatDateAndTime(response.rent_increase_start_date, 'YYYY-MM-DD'),
                            notes: response.notes,

                        };
                        setInitialValues(values);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                setInitialValues({
                    suite_number: '',
                    tenant_name: '',
                    tenant_contact_name: '',
                    tenant_contact_number: '',
                    tenant_contact_email: '',
                    lease_start_date: '',
                    lease_termination_date: '',
                    rented_square_feet: '',
                    monthly_payment: '',
                    rent_increase_start_date: '',            
                    notes: '',
                });
            }
        };
        fetchData();
        if (show) {
            // fetchManagerList();
        }
        authCheckAndFetch();

    }, [show, mode, editId]);



    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{mode == 'view' ? `View ${headerName}` : mode === "edit" ? `Update ${headerName}` : `Add ${headerName}`}</Modal.Title>
            </Modal.Header>
            <Formik

                initialValues={initialValues}
                validationSchema={investmentSchema}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    setIsSubmitting(true);
                    try {
                        let response = []
                        const data_investmentId = editMode ? initialValues?.id : investmentId;
                        const url = editMode ? concatUrlWithId(UPDATE_TENANT, investmentId) : CREATE_TENANT;
                        const bodyPayload = {
                            investment_id: data_investmentId,
                            type: keyValue,
                            suite_number: values.suite_number,
                            tenant_name: values.tenant_name,
                            tenant_contact_name: values.tenant_contact_name,
                            tenant_contact_number: values.tenant_contact_number,
                            tenant_contact_email: values.tenant_contact_email,
                            lease_start_date: values.lease_start_date,
                            lease_termination_date: values.lease_termination_date,
                            rented_square_feet: values.rented_square_feet,
                            monthly_payment: values.monthly_payment,
                            rent_increase_start_date: values.rent_increase_start_date,                  
                            notes: values.notes,

                        };

                        response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);

                        if (response) {

                            notify(`${keyValue} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')

                            if (isSaveNew == true) {
                                actions.resetForm();
                                setIsFieldClear(true);
                            } else {
                                handleClose()
                            }

                        } else {
                            editMode ? notify(`User  update failed...`, 'error') : notify(`Create user  failed...`, 'error');
                        }
                    } catch (error) {
                        notify(error?.response?.data?.error, 'error');

                        console.error('API Error:', error);
                    }
                    setIsSubmitting(false);
                }}
            >
                {props => (
                    <>
                        <Modal.Body >
                            <div>
                                <Form>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <FormField viewMode={viewMode} fieldName="tenant_name" label="Tenant Name" value={initialValues.tenant_name} maxLength={200} />
                                            <FormField viewMode={viewMode} fieldName="tenant_contact_name" label="Tenant Contact Name" value={initialValues.tenant_contact_name} maxLength={200} />
                                            {/* {checkPermission(userPermissions, 'isAdmin') && <SelectField viewMode={viewMode} fieldName="manager" label="Manager" isRequired={false} value={initialValues.manager} options={managerOptions} values={props.values} setFieldValue={props.setFieldValue} />} */}
                                            <FormField viewMode={viewMode} fieldName="tenant_contact_email" label="Tenant Contact Email" value={initialValues.tenant_contact_email} maxLength={200} />
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="formrow-holding-company-input">Tenant Contact Number</label>
                                                {!viewMode && (
                                                    <Field className="form-control" maxLength={14} readOnly={viewMode}
                                                        type="tel" id="formrow-holding-company-input" name="tenant_contact_number"
                                                        onInput={(e) => {
                                                            const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                                            const formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                                            e.target.value = formattedValue;
                                                        }}
                                                    />
                                                )}
                                                {viewMode && <div className='view-label'>{formatContactNumber(initialValues.tenant_contact_number)}</div>}
                                                <ErrorMessage name="tenant_contact_number" component="div" className="error-message" />
                                            </div>
                                            <FormField viewMode={viewMode} fieldName="rented_square_feet" label="Rented Square Feet" value={initialValues.rented_square_feet} maxLength={200} isRequired={false} />
                                            <CurrencyField viewMode={viewMode} fieldName="monthly_payment" label="Monthly Payment" isRequired={false} values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.monthly_payment ? true : false} auditValue={initialValues.monthly_payment} />

                                        </div>
                                        <div className='col-6'>
                                            <FormField viewMode={viewMode} fieldName="suite_number" label="Suite Number" value={initialValues.suite_number} maxLength={18} />
                                            <DatePickerField viewMode={viewMode} fieldName="lease_start_date" label="Lease Start Date" value={initialValues.lease_start_date} disablePastDate={true} isRequired={false} />
                                            <DatePickerField viewMode={viewMode} fieldName="lease_termination_date" label="Lease Termination Date" value={initialValues.lease_termination_date} disablePastDate={true} isRequired={false} />
                                            <DatePickerField viewMode={viewMode} fieldName="rent_increase_start_date" label="Next Rent Increase Start Date" value={initialValues.rent_increase_start_date} disablePastDate={true} isRequired={false} />
                                            <TextAreaField viewMode={viewMode} fieldName="notes" label="Notes" value={initialValues.notes} maxLength={1000} isRequired={false} />
                                        </div>

                                    </div>
                                </Form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ justifyContent: 'center' }}>
                            <Button type="reset" variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            {!viewMode && <Button type="submit" variant="primary" disabled={isSubmitting} onClick={() => { props.submitForm(); setIsSaveNew(false); }} className="btn btn-primary w-md">
                                {editMode ? 'Update' : 'Save'}
                            </Button>}
                            {editMode != true && (!viewMode && <Button type="submit" onClick={() => { props.submitForm(); setIsSaveNew(true); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</Button>)}
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>)
}
export default TenantModelForm;