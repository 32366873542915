import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { VERIFY_PASSWORD } from '../../api/endpoints';
import {post} from '../../api'



export default function PasswordConfirmation({ showViewModal, handleDelete, handleCloseViewModal }) {
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const [Invalidpass, setInvalidpass] = useState('');


    return (
        <Modal show={showViewModal} onHide={handleCloseViewModal}>
            <Modal.Header closeButton>
                <Modal.Title>Enter Password</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{ password: '' }}
                validate={(values) => {
                    const errors = {};
                    if (!values.password) {
                        errors.password = 'Password is required!';
                    }

                    return errors;
                }}
                onSubmit={async (values) => {
                    setIsSubmitButtonDisabled(true);
                    await new Promise((r) => setTimeout(r, 500));
                    try {

                        const passwordURL = await post(VERIFY_PASSWORD, { password: values.password });
                        console.log('Password URL :: ', passwordURL);

                        if (passwordURL.success == true) {
                            handleDelete()
                        } else {
                            setInvalidpass(passwordURL.error);
                        }
                        setIsSubmitButtonDisabled(false);
                        // setLoading(false);
                    } catch (error) {
                        setIsSubmitButtonDisabled(false);
                        setInvalidpass('Invalid Password');
                        console.error('API Error:', error);
                    }
                }}
            >
               {(props) => (
                <>
                        <Modal.Body>
                            <Form>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="formrow-stock-code-input">Password</label>
                                    <Field className="form-control" type="password" id="formrow-stock-code-input" name="password" />
                                    <ErrorMessage className="text-danger" name="password" component="div" />
                                </div>
                                <div className="form-group mb-3">
                                    <div className="text-danger">
                                        {Invalidpass}
                                        <ErrorMessage className="text-danger" name="status" />
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="reset" variant="secondary" onClick={handleCloseViewModal}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitButtonDisabled} onClick={props.submitForm} className="btn btn-primary w-md">
                                Confrim
                            </Button>
                        </Modal.Footer>
                    </>

               )} 
            </Formik>
        </Modal>
    )
}