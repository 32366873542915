import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* Layouts */
import AdminLayout from "./Layouts/AdminLayout";
import NoLayout from "./Layouts/NoLayout";

import Loader from "./components/Loader";
/* Pages */
import StockList from "./pages/investment/StockHoldings/StockList";
import Login from "./pages/Login";
import AddStock from "./pages/investment/StockHoldings/AddStock";
import Profile from "./pages/Profile";
import Overview from "./pages/Overview";
import ArchivedList from "./pages/ArchivedList"
import RealEstateList from "./pages/investment/RealEstate/RealEstateList"
import AddRealEstate from "./pages/investment/RealEstate/AddRealEstate";
import MineralAssetList from "./pages/investment/MineralAssets/MineralAssetList";
import AddMineralAsset from "./pages/investment/MineralAssets/AddMineralAsset";
import AddPersonalAssets from "./pages/investment/PersonalAssets/AddPersonalAssets";
import PersonalAssetsList from "./pages/investment/PersonalAssets/PersonalAssetsList";
import RealEstateHoldingList from "./pages/investment/RealEstateHoldings/RealEstateHoldingDetails";
import AddRealEstateHoldings from "./pages/investment/RealEstateHoldings/AddRealEstateHoldings";
import { PageLoader } from "./Context/PageLoader";
import SetPassword from "./pages/SetPassword";
import ForgetPassword from "./pages/ForgetPassword";
/* CSS */
import './App.css';
import './components/components.css';
import UserList from "./pages/userManagement/UserList";
import UserInfo from "./pages/userManagement/UserInfo";
import EstatePlanning from "./pages/EstatePlannig/EstatePlanning";
import OperatingBusiness from "./pages/operatingBusiness/OperatingBusiness";
import OperatingBusinessView from "./pages/operatingBusiness/OperatingBusinessView";
import OperatingBusinessForm from "./pages/operatingBusiness/OperatingBusinessForm";
import NetWorthList from "./pages/NetWorthList";
import ContingentLiabilityList from "./pages/ContingentLiabilityList";
import TrusteeInstructions from "./pages/TrusteeInstructions";
import TabComponent from "./components/TabComponent";
import ReminderTab from "./pages/reminder/ReminderTab";


const isAuthenticated = () => {
    // Retrieve authToken from local storage
    const authToken = localStorage.getItem("authToken");
    // Check if authToken exists and is not expired (you can add additional checks here)
    return authToken !== null;
}
const allowURLs = ['/set-password/', '/forget-password']
// PrivateRoute component to protect routes

const AdminRoute = () => {
    return (
        <ProtectedRoute>
            <AdminLayout>
                <Outlet />
            </AdminLayout>
        </ProtectedRoute>
    );
};


const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated()) {
        return <Navigate to="/login" replace />;
    }
    return children;
};


function App() {
    const [isLoading, setLoading] = useState(true);
    return (
        <PageLoader.Provider value={{ isLoading, setLoading }}>
            <>
                <ToastContainer />

                {isLoading && <Loader />}
                <BrowserRouter>
                    <Routes>
                        {isAuthenticated() ? (<>
                            <Route path="/" element={<AdminRoute />}>
                                <Route path='portfolio-summary' element={<Overview />} />
                                <Route path='' element={<Navigate to="/portfolio-summary" replace />} />
                                <Route path='net-worth-list' element={<NetWorthList />} />
                                <Route path='liquid-asset-list' element={<StockList />} />
                                <Route path='add-liquid-asset' element={<AddStock />} />
                                <Route path='edit-liquid-asset/:investmentID' element={<AddStock />} />
                                <Route path='view-liquid-asset/:investmentID' element={<AddStock />} />
                                <Route path='profile' element={<Profile />} />
                                <Route path='dataTable' element={<dataTable />} />
                                <Route path='archived-list' element={<ArchivedList />} /> 
                                <Route path='private-equity-list' element={<RealEstateList />} />
                                <Route path='personal-assets-list' element={<PersonalAssetsList />} />
                                <Route path='edit-personal-assets/:investmentID' element={<AddPersonalAssets />} />
                                <Route path='view-personal-assets/:investmentID' element={<AddPersonalAssets />} />
                                <Route path='add-private-equity' element={<AddRealEstate />} />
                                <Route path='add-personal-assets' element={<AddPersonalAssets />} />
                                <Route path='edit-private-equity/:investmentID' element={<AddRealEstate />} />
                                <Route path='view-private-equity/:investmentID' element={<AddRealEstate />} />
                                <Route path='real-estate-holding-list' element={<RealEstateHoldingList />} />
                                <Route path='add-real-estate-holdings' element={<AddRealEstateHoldings />} />
                                <Route path='edit-real-estate-holdings/:investmentID' element={<AddRealEstateHoldings />} />
                                <Route path='view-real-estate-holdings/:investmentID' element={<AddRealEstateHoldings />} />
                                <Route path='royalty-income-list' element={<MineralAssetList />} />
                                <Route path='add-royalty-income' element={<AddMineralAsset />} />
                                <Route path='edit-royalty-income/:investmentID' element={<AddMineralAsset />} />
                                <Route path='view-royalty-income/:investmentID' element={<AddMineralAsset />} />
                                <Route path='users' element={<UserList />} />
                                <Route path='user-info/:userID' element={<UserInfo />} />
                                {/* <Route path='estate-planning' element={<EstatePlanning />} /> */}
                                <Route path='estate-planning' element={<TabComponent EstatePlannings ={true}/>} />
                                <Route path='operating-business' element={<OperatingBusiness />} />
                                <Route path='operating-business-view/:businessID' element={<OperatingBusinessView />} />
                                <Route path='add-operating-business' element={<OperatingBusinessForm />} />
                                <Route path='edit-operating-business/:businessID' element={<OperatingBusinessForm />} />
                                <Route path='view-operating-business/:businessID' element={<OperatingBusinessForm />} />
                                <Route path='contingent-liability-list' element={<ContingentLiabilityList />} />
                                <Route path='reminder-list' element={<ReminderTab />} />
                                <Route path='trustee-instructions' element={<TrusteeInstructions />} />
                            </Route>
                            <Route path='set-password/:token' element={<SetPassword />} />
                            <Route path="*" element={<Navigate to="/portfolio-summary" replace />} />
                        </>
                        ) : (
                            <>
                                <Route path="/" element={<NoLayout />}>
                                    <Route path="/login" element={<Login />} />
                                    <Route path='' element={<Navigate to="/login" replace />} />
                                    <Route path="/set-password/:token" element={<SetPassword />} />
                                    <Route path="/forget-password" element={<ForgetPassword />} />
                                </Route>
                                <Route path="*" element={<Navigate to="/login" replace />} />
                            </>
                        )}
                    </Routes>
                </BrowserRouter>
            </>
        </PageLoader.Provider >
    );
}


export default App;
