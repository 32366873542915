
import { useState, useEffect } from 'react';
import { get, put } from '../../api'
import 'react-toastify/dist/ReactToastify.css'
import { concatUrlWithId } from '../../utils/commonUtils';
import { toast } from 'react-toastify';
import { GET_USER_INVESTMENT_PERMISSIONS, UPDATE_USER_INVESTMENT_PERMISSIONS } from '../../api/endpoints';
import Datatable from '../../components/Table/Datatable';

function InvesmentPermissionTable({ userId, isManager = false, }) {
    const notify = (message, type) => toast(message, { type });
    const [loading, setLoading] = useState(true)
    const [columns, setColumns] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
    const [userSelectedPermissionIds, setUserSelectedPermissionIds] = useState([]);
    const [selectedInvestmentId, setSelectedInvestmentId] = useState(null);
    const [selectedCat, setSelectedCat] = useState('all');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({ label: 'All', value: 'all' });
    const [OrginalData, setOrginalData] = useState([]);
    const [investmentAndPermission, setinvestmentAndPermission] = useState([]);
    const [removedPermissions, setRemovedPermissions] = useState([]);
    const [Render, setRender] = useState(false);
    const [filterOptions, setFilterOptions] = useState([
        { label: 'All', value: 'all' }
    ]);
    const types = {
        "Personal Assets": "PersonalAssets",
        "Real Estate Holdings": "RealEstateHoldings",
        "Private Equity": "RealEstate",
        "Royalty Income": "MineralAssets",
        "Liquid Assets": "Stock",
    };
    const fetchData = async () => {
        let tempPermission = permissionList;
        let tempOriginalData = OrginalData;
        if (!OrginalData.length || !permissionList.length) {
            const { combinedData, permission } = await processData(userId);
            setPermissionList(permission);
            tempPermission = permission
            setOrginalData(combinedData);
            tempOriginalData = combinedData

        }
        prepareTableData(
            tempOriginalData,
            tempPermission,
            selectedFilter,
            userSelectedPermissionIds,
            investmentAndPermission,
            removedPermissions,
            disabledCheckboxes
        );
    };
    useEffect(() => {
        setFilteredData([]);
        fetchData();
    }, [userSelectedPermissionIds, removedPermissions, selectedFilter, Render, disabledCheckboxes]);

    useEffect(() => {
        fetchData();
    }, []);

    const processData = async (userId) => {
        try {
            const data = await get(concatUrlWithId(GET_USER_INVESTMENT_PERMISSIONS, `${userId}`));
            const { data: combinedData = [], Overallpermissions: permission } = data;
            return { combinedData, permission };
        } catch (error) {
            console.error("Error while fetching data:", error);
            notify("Something went wrong while fetching data", "error");
            return { combinedData: [], permission: [] };
        }
    };
    const getRelatedPermissionIds = (permissionId, permissionLists) => {
        const permission = permissionLists.find((item) => item.id === permissionId);
        return permission?.related_permissionID ? [permission.related_permissionID] : [];
    };


    const prepareTableData = (combinedDatas, permissionLists, selectedFilter, userSelectedPermissionIds, investmentAndPermission, removedPermissions, disabledCheckbox) => {
        const filteredData =
            selectedFilter.value !== "all"
                ? combinedDatas.filter((item) => item.group.category_name === selectedFilter.value)
                : combinedDatas;

        const generatedColumns = [];
        const tableData = [];
        const columnNames = new Set();
        const investmentPermissionsList = investmentAndPermission;
        const disabledCheckboxes = disabledCheckbox;
        const flattenedInvestements = filteredData.flatMap((item) => item.investments)
        // Add user-selected permissions
        userSelectedPermissionIds.forEach((item) => {
            if (!investmentPermissionsList.some((perm) => perm.investment_id === item.investment_id && perm.id === item.id)) {
                investmentPermissionsList.push(item);
            }
        });
        removedPermissions.forEach((item) => {
            const index = investmentPermissionsList.findIndex(
                (perm) => perm.investment_id == item.investment_id && perm.id === item.id
            );
            if (index !== -1) {
                investmentPermissionsList.splice(index, 1); // Remove the matching item
            }
        });

        // Generate filter options
        const filterOptions = [
            { label: "All", value: "all" },
            ...combinedDatas.map((item) => ({
                label: item.group.category_name,
                value: item.group.category_name,
            })),
        ];
        setFilterOptions(filterOptions);

        const RelatedPermissionExtract = (permissionLists, permissionId, investmentId) => {
            const relatedPermissions = getRelatedPermissionIds(permissionId, permissionLists);

            relatedPermissions.forEach((relatedId) => {
                const isAlreadyDisabled = disabledCheckboxes.some((item) => item.id == relatedId && item.investment_id == investmentId);
                if (!isAlreadyDisabled) {
                    disabledCheckboxes.push({ id: relatedId, investment_id: investmentId });
                }
            });
        };


        const checkStatus = (investmentId, permissionId) => {
            let result = false;
            if (removedPermissions.some((perm) => perm.investment_id === investmentId && perm.permission_id == permissionId)) {
                result = false;
            }
            if (userSelectedPermissionIds.some((perm) => perm.investment_id === investmentId && perm.id === permissionId)) {
                result = true; // Explicitly selected
            }
            result = investmentPermissionsList.some((perm) => perm.investment_id === investmentId && perm.id === permissionId);

            return result;
        };

        filteredData.forEach((category) => {
            const { group, investments, permissions } = category;

            // Process investments and their permissions
            if (!removedPermissions.length > 0 && !userSelectedPermissionIds.length > 0) {
                investments.forEach((investment) => {
                    investment.user_permissions.forEach((item) => {
                        if (!investmentPermissionsList.some((perm) => perm.investment_id === item.investment_id && perm.id === item.id)) {
                            RelatedPermissionExtract(permissionLists, item.id, item.investment_id);
                            investmentPermissionsList.push(item);
                        }
                    });
                });
            }
            const getPermissionName = (permissionId, type) => {
                const permission = permissionLists.find((item) => item.id === permissionId);
                if (permission && !type) {
                    return removeWordsFromString(permission.permission_name);
                }
                if (permission && type) {
                    return permission.permission_type;
                }
                return null;
            };
            // Helper function for header checkbox state
            const getHeaderCheckboxState = (permissionId) => {

                const totalcount = flattenedInvestements.filter((items) => {
                    return permissionLists.some((permission) => permission.permission_type === types[items.category_name] && removeWordsFromString(permission.permission_name) === permissionId
                    );
                }).length;

                const selectedCount = investmentPermissionsList.filter((item) => {
                    const permissionName = getPermissionName(item.id, false);
                    const permissionType = getPermissionName(item.id, true);
                    return (
                        permissionName === permissionId &&
                        (selectedFilter.value === 'all' || permissionType === types[selectedFilter.value])
                    );
                }).length;
                if (selectedFilter.value === 'all') {
                    if (selectedCount == totalcount) return "checked";
                    if (selectedCount > 0) return "indeterminate";
                    return "unchecked";
                } else {
                    if (selectedCount == group.investment_count) return "checked";
                    if (selectedCount > 0) return "indeterminate";
                    return "unchecked";
                }
            };

            permissions.forEach((permission) => {
                const { id, permission_name } = permission;
                const permission_name_cleaned = removeWordsFromString(permission_name);
                const isDisabled = (permissionId) => {
                    let result = false;
                    const totalCount = flattenedInvestements.filter((item) =>
                        permissionLists.some(
                            (permission) =>
                                permission.permission_type === types[item.category_name] &&
                                removeWordsFromString(permission.permission_name) === permissionId
                        )
                    ).length;

                    const selectedCount = disabledCheckboxes.filter((item) => {
                        const permissionName = getPermissionName(item.id, false);
                        const permissionType = getPermissionName(item.id, true);
                        return (
                            permissionName === permissionId &&
                            (selectedFilter.value === 'all' || permissionType === types[selectedFilter.value])
                        );
                    }).length;

                    if (selectedCount === totalCount) {
                        result = true;
                    }


                    return result;
                };

                if (!columnNames.has(permission_name_cleaned) && permission_name_cleaned !== 'Create') {
                    columnNames.add(permission_name_cleaned);
                    generatedColumns.push({
                        dataField: permission_name_cleaned,
                        text: (
                            <div style={{ display: "flex", minWidth: "150px", alignItems: "center" }}>
                                <input
                                    type="checkbox"
                                    ref={(checkbox) => {
                                        const state = getHeaderCheckboxState(permission_name_cleaned);
                                        if (checkbox) {
                                            checkbox.checked = state === "checked";
                                            checkbox.indeterminate = state === "indeterminate";
                                        }

                                    }}

                                    disabled={isDisabled(permission_name_cleaned)}
                                    onChange={(e) =>

                                        handleHeaderCheckboxChange(
                                            e.target.checked,
                                            permission_name_cleaned,
                                            investments,
                                            permissions,
                                            permissionLists,
                                            combinedDatas, // OrginalData
                                            investmentAndPermission,
                                            userSelectedPermissionIds,
                                            selectedFilter
                                        )
                                    }
                                    style={{ width: "17px", height: "17px", marginRight: "10px" }}
                                />
                                {permission_name_cleaned}
                            </div>
                        ),
                        sort: false,
                        formatter: (cell, row) => {
                            if (cell && cell.permission_name && cell.id) {
                                // Function to determine if the checkbox is disabled
                                const isDisabled = disabledCheckboxes.includes(cell.id) &&
                                    investmentPermissionsList.some(
                                        (perm) => perm.id === cell.id && perm.investment_id === row.id
                                    );

                                // Determine the checked status for the checkbox

                                return (
                                    <input
                                        type="checkbox"
                                        name={cell.permission_name}
                                        value={cell.id}
                                        ref={(checkbox) => {
                                            const tempCheckStatus = checkStatus(row.id, cell.id) || false;
                                            if (checkbox && checkbox.checked !== tempCheckStatus) {
                                                checkbox.checked = tempCheckStatus; // Synchronize the ref with the checked state
                                            }
                                        }}
                                        // checked={checkStatus(row.id, cell.id) || false}
                                        // disabled={isDisabled}
                                        disabled={disabledCheckboxes.some((item) => item.id == cell.id && item.investment_id == row.id)}
                                        onChange={(e) =>
                                            handleRowCheckboxChange(e, row.id, cell.id, permissionLists)
                                        }
                                        style={{
                                            width: "17px",
                                            height: "17px",
                                            display: "block",
                                            margin: "0 auto",
                                        }}
                                    />
                                );
                            }
                            return cell; // Return the cell content if it doesn't meet the conditions
                        }
                    });
                }
            });

            // Prepare row data
            investments.forEach((investment) => {
                const rowData = {
                    id: investment.id,
                    investment_name: investment.investment_name,
                    category_name: group.category_name,
                };

                permissions.forEach((permission) => {
                    const permission_name = removeWordsFromString(permission.permission_name);
                    rowData[permission_name] = {
                        id: permission.id,
                        permission_name: permission.permission_name,
                    };
                });

                tableData.push(rowData);
            });
        });

        // Add default column for investment name
        generatedColumns.unshift({
            dataField: "investment_name",
            text: "Investment Name",
            sort: true,
            headerStyle: { textAlign: "center", minWidth: "200px" },
        });

        setDisabledCheckboxes(disabledCheckboxes);
        setinvestmentAndPermission(investmentPermissionsList);
        setColumns(generatedColumns);
        setTimeout(() => {
            setFilteredData(tableData);
        }, 0);
    };


    function handleRowCheckboxChange(e, investmentId, permissionId, permissionLists) {
        // setFilteredData((prevFilter) => {

        const isChecked = e.target.checked;
        const matchID = permissionLists.find(permission => permission.id === permissionId);
        const relativePermissionsId = matchID?.related_permissionID;

        let tempInvestmentAndPermission = [...investmentAndPermission];
        let tempUserSelectedPermissionIds = [...userSelectedPermissionIds];
        let tempRemovedPermissions = [...removedPermissions];
        let tempDisabledCheckboxes = [...disabledCheckboxes];

        if (isChecked) {

            // Add the main permission and related permissions
            if (!tempInvestmentAndPermission.some(perm => perm.investment_id === investmentId && perm.id === permissionId)) {
                tempInvestmentAndPermission.push({ investment_id: investmentId, id: permissionId });
            }

            if (relativePermissionsId && !tempInvestmentAndPermission.some(perm => perm.investment_id === investmentId && perm.id == relativePermissionsId)) {
                tempInvestmentAndPermission.push({ investment_id: investmentId, id: relativePermissionsId });
            }

            if (!tempUserSelectedPermissionIds.some(perm => perm.investment_id === investmentId && perm.id === permissionId)) {
                tempUserSelectedPermissionIds.push({ investment_id: investmentId, id: permissionId });
            }

            if (relativePermissionsId && !tempUserSelectedPermissionIds.some(perm => perm.investment_id === investmentId && perm.id == relativePermissionsId)) {
                tempUserSelectedPermissionIds.push({ investment_id: investmentId, id: relativePermissionsId });
            }

            tempRemovedPermissions = tempRemovedPermissions.filter(perm => !(perm.investment_id === investmentId && perm.id === permissionId));
            if (relativePermissionsId) {
                tempRemovedPermissions = tempRemovedPermissions.filter(perm => !(perm.investment_id === investmentId && perm.id == relativePermissionsId));
            }
            if (relativePermissionsId && !tempDisabledCheckboxes.some(perm => perm.investment_id === investmentId && perm.id === relativePermissionsId)) {
                tempDisabledCheckboxes.push({ investment_id: investmentId, id: relativePermissionsId });
            }

        } else {

            // Remove the main permission and related permissions            
            tempInvestmentAndPermission = tempInvestmentAndPermission.filter(perm => !(perm.investment_id === investmentId && perm.id == permissionId));

            if (relativePermissionsId) {
                tempInvestmentAndPermission = tempInvestmentAndPermission.filter(perm => !(perm.investment_id === investmentId && perm.id == relativePermissionsId));
            }

            tempUserSelectedPermissionIds = tempUserSelectedPermissionIds.filter(perm => !(perm.investment_id === investmentId && perm.id === permissionId));

            if (relativePermissionsId) {
                // tempUserSelectedPermissionIds = tempUserSelectedPermissionIds.filter(perm => !(perm.investment_id === investmentId && perm.id == relativePermissionsId));
            }

            if (!tempRemovedPermissions.some(perm => perm.investment_id === investmentId && perm.id === permissionId)) {

                tempRemovedPermissions.push({ investment_id: investmentId, id: permissionId });
            }
            if (relativePermissionsId) {
                const isRelativeStillUsed = tempUserSelectedPermissionIds.some(perm => {
                    const relatedMatch = permissionLists.find(permission => permission.id === perm.id);
                    return relatedMatch?.related_permissionID == relativePermissionsId && perm.investment_id === investmentId;
                });
                const isRelativeStillUsed1 = tempInvestmentAndPermission.some(perm => {
                    const relatedMatch = permissionLists.find(permission => permission.id === perm.id);
                    return relatedMatch?.related_permissionID == relativePermissionsId && perm.investment_id === investmentId;
                });

                if (!isRelativeStillUsed && !isRelativeStillUsed1) {
                    tempDisabledCheckboxes = tempDisabledCheckboxes.filter(
                        item => !(item.id === relativePermissionsId && item.investment_id === investmentId)
                    );
                } else if (!tempUserSelectedPermissionIds.some(item => item.id == relativePermissionsId && item.investment_id === investmentId)) {
                    // Add related permission to userSelectedPermissions if not already included
                    tempUserSelectedPermissionIds.push({ investment_id: investmentId, id: relativePermissionsId });
                }
            }

        }

        // Update the states
        setinvestmentAndPermission(tempInvestmentAndPermission);
        setUserSelectedPermissionIds(tempUserSelectedPermissionIds);
        setRemovedPermissions(tempRemovedPermissions);
        setDisabledCheckboxes(tempDisabledCheckboxes);
    }

    const handleHeaderCheckboxChange = (
        isChecked,
        permissionName,
        investments,
        permissions,
        permissionLists,
        originalData,
        investmentAndPermission,
        userSelectedPermissionIds,
        selectedFilter
    ) => {
        const permissionIds = [];

        // Filter permissions based on the selected filter
        permissionLists.forEach((item) => {
            if (
                removeWordsFromString(item.permission_name) === permissionName &&
                (selectedFilter.value === "all" || item.permission_type === types[selectedFilter.value])
            ) {
                permissionIds.push(item);
            }
        });

        let updatedPermissions = [...investmentAndPermission];
        let updatedUserSelected = [...userSelectedPermissionIds];
        let updatedRemovedPermissions = [...removedPermissions];
        let updatedDisabledCheckboxes = [...disabledCheckboxes];

        // Determine which investments to process based on the filter
        const investmentsToProcess =
            selectedFilter.value === "all"
                ? originalData.flatMap((item) => item.investments)
                : investments;

        investmentsToProcess.forEach((investment) => {
            const investmentId = investment.id;
            const investmentCategory = investment.category_name;

            permissionIds.forEach((permission) => {
                const permissionId = permission.id;
                const relatedPermissionId = permission.related_permissionID;

                // Match permissions with the corresponding investment categories
                if (types[investmentCategory] === permission.permission_type) {
                    if (isChecked) {

                        // Add permissions and related permissions
                        if (!updatedPermissions.some((perm) => perm.investment_id === investmentId && perm.id === permissionId)) {
                            updatedPermissions.push({ investment_id: investmentId, id: permissionId });
                            updatedUserSelected.push({ investment_id: investmentId, id: permissionId });
                        }

                        if (
                            relatedPermissionId &&
                            !updatedPermissions.some((perm) => perm.investment_id === investmentId && perm.id == relatedPermissionId)
                        ) {
                            updatedPermissions.push({ investment_id: investmentId, id: relatedPermissionId });
                            updatedUserSelected.push({ investment_id: investmentId, id: relatedPermissionId });
                        }

                        updatedRemovedPermissions = updatedRemovedPermissions.filter(
                            (perm) => !(perm.investment_id === investmentId && perm.id === permissionId)
                        );
                        if (relatedPermissionId) {
                            updatedRemovedPermissions = updatedRemovedPermissions.filter(
                                (perm) => !(perm.investment_id === investmentId && perm.id == relatedPermissionId)
                            );
                        }
                        if (
                            relatedPermissionId &&
                            !updatedDisabledCheckboxes.some((perm) => perm.investment_id === investmentId && perm.id == relatedPermissionId)
                        ) {
                            updatedDisabledCheckboxes.push({ investment_id: investmentId, id: relatedPermissionId });
                        }
                    } else {
                        // Remove permissions and related permissions
                        updatedPermissions = updatedPermissions.filter(
                            (perm) => !(perm.investment_id === investmentId && perm.id === permissionId)
                        );

                        if (relatedPermissionId) {
                            updatedPermissions = updatedPermissions.filter(
                                (perm) => !(perm.investment_id === investmentId && perm.id == relatedPermissionId)
                            );
                        }

                        updatedUserSelected = updatedUserSelected.filter(
                            (perm) => !(perm.investment_id === investmentId && perm.id === permissionId)
                        );

                        if (relatedPermissionId) {
                            // updatedUserSelected = updatedUserSelected.filter(
                            //     (perm) => !(perm.investment_id === investmentId && perm.id == relatedPermissionId)
                            // );
                        }

                        const isStillUsed = updatedUserSelected.some((perm) => {
                            const match = permissionLists.find((item) => item.id === perm.id);
                            return (
                                match?.related_permissionID == relatedPermissionId &&
                                perm.investment_id === investmentId
                            );
                        });

                        const isStillUsed1 = updatedPermissions.some((perm) => {
                            const match = permissionLists.find((item) => item.id === perm.id);
                            return (
                                match?.related_permissionID == relatedPermissionId &&
                                perm.investment_id === investmentId
                            );
                        });

                        if (!isStillUsed && !isStillUsed1) {
                            updatedDisabledCheckboxes = updatedDisabledCheckboxes.filter(
                                (item) => !(item.id == relatedPermissionId && item.investment_id === investmentId)
                            );
                        } else if (!updatedUserSelected.some((item) => item.id == relatedPermissionId && item.investment_id === investmentId)) {
                            // Add related permission to userSelectedPermissions if not already included
                            updatedUserSelected.push({ investment_id: investmentId, id: relatedPermissionId });
                        }
                        if (
                            !updatedRemovedPermissions.some(
                                (perm) =>
                                    perm.investment_id === investmentId &&
                                    perm.id === permissionId
                            ) &&
                            !updatedUserSelected.some(
                                (perm) =>
                                    perm.investment_id === investmentId &&
                                    perm.id === permissionId
                            ) &&
                            !updatedPermissions.some(
                                (perm) =>
                                    perm.investment_id === investmentId &&
                                    perm.id === permissionId
                            ) &&
                            !updatedDisabledCheckboxes.some(
                                (perm) =>
                                    perm.investment_id === investmentId &&
                                    perm.id === permissionId
                            ) // Exclude items in disabledCheckboxes
                        ) {

                            updatedRemovedPermissions.push({
                                investment_id: investmentId,
                                id: permissionId,
                            });
                            if (relatedPermissionId) {
                                updatedRemovedPermissions.push({
                                    investment_id: investmentId,
                                    id: relatedPermissionId,
                                });
                            }
                        }
                    }
                }
            });

            // Remove conflicts between `updatedRemovedPermissions` and `updatedUserSelected`

            updatedRemovedPermissions = updatedRemovedPermissions.filter(
                (perm) =>
                    !updatedUserSelected.some(
                        (selected) =>
                            selected.id === perm.id &&
                            selected.investment_id === perm.investment_id
                    )
            );
            // Ensure all `disabledCheckboxes` are reflected in `userSelectedPermissionIds`
            updatedDisabledCheckboxes.forEach((item) => {
                if (
                    !updatedUserSelected.some(
                        (selected) =>
                            selected.id === item.id &&
                            selected.investment_id === item.investment_id
                    )
                ) {
                    updatedUserSelected.push(item);
                }
            });
        });

        // Batch update states
        setinvestmentAndPermission(updatedPermissions);
        setUserSelectedPermissionIds(updatedUserSelected);
        setRemovedPermissions(updatedRemovedPermissions);
        setDisabledCheckboxes(updatedDisabledCheckboxes);
        setRender((prev) => !prev); // Re-render UI
    };


    function removeWordsFromString(originalString) {
        let wordsToRemove = ['Investment', 'Stock', 'New', 'RealEstate', 'Holdings', 'Mineral Asset', 'Personal Asset', 'Liquid Asset', 'Royalty Income', 'Private Equity']
        // Create a regex pattern to match the words to remove
        const regexPattern = new RegExp(wordsToRemove.join('|'), 'gi');
        // Replace occurrences of the words with an empty string, then clean up spaces
        return originalString.replace(regexPattern, '').replace(/\s+/g, ' ').trim();
    }

    const handleFilterChange = async (selectedValue, selectedLabel) => {
        setLoading(true);
        setSelectedCat(selectedValue)
        setSelectedFilter({ label: selectedLabel, value: selectedValue })
        setLoading(false);
    }

    const userUpdatePermission = async (values) => {
        try {
            let url = UPDATE_USER_INVESTMENT_PERMISSIONS;
            const bodyPayload = {
                UserId: userId,
                PermissionIds: userSelectedPermissionIds,
                Removed_ids: removedPermissions,
            };
            const response = await put(url, bodyPayload);
            // const response = '';
            if (response) {
                notify(`Permission shared successfully`, 'success');
            } else {
                notify('Permission Updated failed...', 'error');
            }
        } catch (error) {
            notify(error?.response?.data?.error, 'error');
            console.error('API Error:', error);
        }
    };

    return (
        <>
            <div>
                <div className='row'>
                    <div className='col-6'>
                        <legend className='text-bold'>Investment Level Permission</legend>
                    </div>
                </div>

                {/* {!loading && filteredData.length > 0 ? ( */}
                <Datatable data={filteredData} columns={columns} disableCsv={true} FreezeColumn={true} filterOptions={filterOptions} handleFilterChange={handleFilterChange} selectedFilter={selectedFilter} />


                {/* {loading && <div style={{ margin: '100px' }}>Loading...</div>} */}

                {/* Save button below the table */}
                <div className="text-right mt-3">
                    <button className="btn btn-primary" onClick={async () => await userUpdatePermission()}>
                        Save
                    </button>

                </div><br></br>
            </div>
        </>
    );

}
export default InvesmentPermissionTable;