
import { useState, useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import Footer from "../../../components/Footer";
import { PageLoader } from '../../../Context/PageLoader';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../../../components/Table/Datatable';
import DataChart from '../../../components/DataChart';
import { get, put, remove } from '../../../api';
import { GET_INVESTMENTS, DELETE_INVESTMENT_BY_ID, ARCHIVE_INVESTMENT_BY_ID, GET_INVESTMENT_BY_ID, GET_STOCK_INV_INTEREST } from '../../../api/endpoints'
import { checkPermission, cleanURL, concatUrlWithId, formatDateAndTime, mapDataWithHeaders, refreshCount } from '../../../utils/commonUtils';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PasswordConfirmation from '../../../components/Forms/PasswordConfirmation';
import { exportLiquidAssest } from '../../../api/exportApi';
function StockList() {
  const [investmentList, setInvestmentList] = useState([]);
  const [selectedInvList, setSelectedInvList] = useState([]);
  const [selectedChartValue, setSelectedChartValue] = useState("");
  const [filteredChartValue, setFilteredChartValue] = useState("");
  const [userPermissions, setUserPermissions] = useState("");
  const [showAction, setShowAction] = useState(false);
  const [showReminderModel, setShowReminderModel] = useState(false);
  const [selectedInvID, SetSelectedInvID] = useState('')
  const [enablePrint, SetEnablePrint] = useState(false)
  const location = useLocation();
  const locationsearch = window.location.search;
  const locationData = location.state;
  const { loading, setLoading } = useContext(PageLoader);
  const history = useNavigate();
  const notify = (message, type) => toast(message, { type });
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const category_id = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
  const [contacts, setContacts] = useState([]);
  const [BackupBeforeDelete, setBackupBeforeDelete] = useState(false);
  const [chartClick, setChartClick] = useState(false)

  const perviousURLRef = useRef([]);
  const [removeChartIndex, setRemoveChartIndex]  = useState(true);


  // Reminder Task 
  const [showReminderTask, setShowReminderTask] = useState(false);
  const [reminderActionPermission, setReminderActionPermission] = useState(false);
  const [getInvestmentID, setInvestmentID] = useState('');
  const [showPasswordModel, setShowPasswordModel] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ id: '', name: '' });
  const [chartData, setChartData] = useState([]);
  const [isBackNavigation, setIsBackNavigation] = useState(false);

  const [headerValues, setHeaderValues] = useState({
    totalInvestments: 0,
    totalCurrentValues: 0,
    totalDebt: 0,
  });

  const reminderTaskPopUp = (investment_id, actionPermission) => {
    setInvestmentID(investment_id);
    setShowReminderTask(true);
    setReminderActionPermission(actionPermission)
  };
  const handleClose = () => setShowReminderTask(false);

  const handelListUpdate = () => fetchInvestmentList();

  const fetchInvestmentList = async () => {
    try {
      const investments = await get(GET_INVESTMENTS);
      const getInvestment = investments.investments;
      if (getInvestment) {
        let isAction = getInvestment.some((inv) => inv.permission_keys?.length > 1);
        setShowAction(isAction);

        const filterData = getInvestment.filter(value => value.cal_current_value != null);
        setInvestmentList(filterData);
        setSelectedInvList(getInvestment)
        setChartData(investments?.chartData || []);
        setHeaderValues({
          totalInvestments: investments.totals.total_invested,
          totalCurrentValues: investments.totals.total_current_value,
          totalDebt: investments.totals.total_inv_aft_Debt,
        })
        processQueryParams(filterData)
      }
      refreshCount();
    } catch (error) {
      console.log('Error while throwing:: ', error);
    } finally {
      setLoading(false);
    }
  }
  const processQueryParams = (filterData) => {
    const queryString = window.location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const filterValue = params.get('datachart');
      if (filterValue) {
        let decodedValue = decodeURIComponent(filterValue);
        decodedValue = decodedValue.replace(/\+/g, " ");
        let filterInvesmentList = filterData.filter((item) => (decodedValue == item['asset_type']));
        if (filterInvesmentList.length > 0) {
          // setChartClick(true)
          setFilteredChartValue(decodedValue);
        } else {
          // setChartClick(true)
          setFilteredChartValue('')
          URLUpdate(false)
          // const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
          // window.history.pushState({ path: newurl }, '', newurl);
        }
      }
    }
  };


  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    setUserPermissions(tempPermission || []);
    // if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager') || checkPermission(tempPermission, 'view-stock')) {
      fetchInvestmentList()
    // } else {
    //   setLoading(false);
    // }
  };

  // Back navigation issue fix start

  useEffect(() => {
    setLoading(true)
    authCheckAndFetch();
  }, [locationData])

  useEffect(() => {
    let pathAfterDomain = window.location.pathname + window.location.search + window.location.hash;
    let tempPath = cleanURL(pathAfterDomain)
    if (tempPath === '/liquid-asset-list') {
      setRemoveChartIndex(true);
    }else {
      setRemoveChartIndex(false);
    }
  }, [location]);

  useEffect(() => {
    if ((!chartClick || isBackNavigation) && investmentList && locationsearch) {
      processFilterApply(investmentList)
      setIsBackNavigation(false)
    } else if (!locationsearch || !investmentList) {
      authCheckAndFetch()
    }
    setChartClick(false)
  }, [locationsearch])



  const processFilterApply = (filterData) => {
    // setChartClick(true)
    const queryString = window.location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const filterValue = params.get('datachart');
      if (filterValue) {
        let decodedValue = decodeURIComponent(filterValue);
        decodedValue = decodedValue.replace(/\+/g, " ");
        let filterInvesmentList = filterData.filter((item) => (decodedValue == item['asset_type']));
        if (filterInvesmentList.length > 0) {
          setFilteredChartValue(decodedValue);
        } else {
          setFilteredChartValue('')
        }
      } else {
        setFilteredChartValue('')
      }
    } else {
      authCheckAndFetch();
    }
  }

  useEffect(() => {
    const handlePopState = (event) => {
      setChartClick(false);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const URLUpdate = (Add, value, from) => {
    if (Add) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?datachart=' + encodeURIComponent(value);
      if (newurl != window.location.href) {
        window.history.pushState({ path: newurl }, '', newurl);
        perviousURLRef.url = perviousURLRef.url?.length ? [...perviousURLRef.url, window.location.href] : [window.location.href];
      } else {
        setChartClick(false)
      }
    } else {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }

  const handleBackClick = () => {
    const previous = document.referrer;
    const currentDomain = window.location.origin;
    const currentPath = window.location.pathname;
    let pathAfterDomain = window.location.pathname + window.location.search + window.location.hash;
    let tempPath = cleanURL(pathAfterDomain)
    setChartClick(false)
    setIsBackNavigation(true);
    // history(-1);
    if (perviousURLRef?.url?.length) {
        perviousURLRef.url.pop();
        history(-1)
        // window.history.back();
    } else {
       history('/portfolio-summary')
      // if ((previous.includes(currentDomain) || previous === '#' || !previous) && window.history.length > 1) {
      //   // if (tempPath === '/liquid-asset-list' || !previous.includes(currentDomain + '/liquid-asset-list')) {
      //   if (tempPath === '/liquid-asset-list') {
      //     // Avoid looping by redirecting to /portfolio-summary if current is list page and previous is another page
      //     window.location.href = '/portfolio-summary';
      //   } else if (pathAfterDomain.includes('/liquid-asset-list') && pathAfterDomain.includes('?datachart')) {
      //     // alert("1" + previous)
      //     window.history.back();
      //   } else {
      //     window.location.href = '/portfolio-summary';
      //   }
      // } else {
      //   window.location.href = '/portfolio-summary';
      // }
    }
  };

  // Back navigation issue fix end

  const handleChartChange = (selectedValue) => {
    setChartClick(true);
    setRemoveChartIndex(false);
    setFilteredChartValue('')
    if (selectedChartValue == selectedValue) {
      setSelectedInvList(investmentList)
      setSelectedChartValue("")
      URLUpdate(false)

    } else {
      URLUpdate(true, selectedValue)
      let filterInvesmentList = investmentList.filter((item) => (selectedValue == item['asset_type']));
      setSelectedChartValue(selectedValue)
      setSelectedInvList(filterInvesmentList)
    }
  };



  const downloadAndProcessDelete = async (id) => {
    try {
      let result = await exportLiquidAssest('id', id);
      return result;
    } catch (error) {
      console.log('Error in the downloadAndProcedDelete : ', error);
    }
  };

  const handleDelete = async () => {
    const { id, name } = deleteItem;
    try {
      setLoading(true)
      // await exportLiquidAssest('id', id);
      let isBackup = true;
      if (BackupBeforeDelete) {
        isBackup = await downloadAndProcessDelete(id);
      }
      if (isBackup) {
        let deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, id));

        if (deleteInvestment) {
          setShowPasswordModel(false);
          fetchInvestmentList()
        }
        setShowPasswordModel(false);
        setBackupBeforeDelete(false);
        notify(`${name} successfully deleted`, 'success')
      } else {
        notify(`${name} backup Failed: We couldn't complete the backup. Please try again.`, 'warning')
        setLoading(false)
      }
    } catch (error) {
      notify(`Unable to delete ${name}`, 'warning')
      console.log('Error while deleteing the error : ', error)
      setLoading(false);
    }
  };

  const handleEditClick = (investmentId) => {
    setLoading(true);
    history(`/edit-liquid-asset/${investmentId}`, { state: { previousPage: "Liquid Asset View", previousUrl: window.location.pathname + window.location.search } });
  };
  const handleViewClick = (investmentId) => {
    setLoading(true);
    history(`/view-liquid-asset/${investmentId}`, { state: { previousPage: "Liquid Asset View", previousUrl: window.location.pathname + window.location.search } });
  };

  const AddStock = () => {
    setLoading(true);
    // history('/add-liquid-asset', { state: { previousPage: "Liquid Asset List", previousUrl: "/liquid-asset-list" } });
    history('/add-liquid-asset', { state: { previousPage: "Liquid Asset View", previousUrl: window.location.pathname + window.location.search } });
  };

  const revertDelete = () => {
    setDeleteItem({ id: '', name: '' })
  }

  function deleteStock(id, Name) {
    confirmAlert({
      title: 'Delete',
      message: `Are you sure you want to delete ${Name}?`,
      overlayClassName: 'custom-overlay',
      customUI: ({ title, message, onClose }) => (
        <div className="custom-popup">
          <h2>{title}</h2>
          <p>{message}</p>
          <div className="button-group">
            <button
              className="confirm-button confirm-yes"
              onClick={() => {
                setDeleteItem({ id: id, name: Name });
                setShowPasswordModel(true);
                onClose();
              }}
            >
              Yes
            </button>
            <button
              className="confirm-button confirm-backup"
              onClick={() => {
                setDeleteItem({ id: id, name: Name });
                setShowPasswordModel(true);
                setBackupBeforeDelete(true);
                onClose();
              }}
            >
              Backup & Delete
            </button>
            <button className="confirm-button confirm-no" onClick={onClose}>
              No
            </button>
          </div>
        </div>
      )
    });
  }

  const archivedStock = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Archive Liquid Asset',
      message: `Are you sure want to Archive ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleArchived(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const handleArchived = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true });
      if (archive) {
        fetchInvestmentList()
      }
      notify(`${investment_name} has been archived.`, 'warning',)
    } catch (error) {
      console.log('Error while deleteing the error : ', error)
      notify(`Unable to archive ${investment_name}.`, 'warning',)
    }
  };

  const handleOnClickAction = (type, id, name, actionPermission) => {
    switch (type) {
      case 'view':
        handleViewClick(id);
        break;
      case 'edit':
        handleEditClick(id);
        break;
      case 'archive':
        archivedStock(id, name);
        break;
      case 'delete':
        deleteStock(id, name);
        break;
      case 'reminderTask':
        reminderTaskPopUp(id, actionPermission);
        break;
      case 'setreminder':
        handleReminderClick(id, name, actionPermission);
        break;
      default:
        break;
    }
  };

  // Reminder Function Start -------
  const handleReminderClick = (id, name, actionPermission) => {
    // console.log('Aciton permission :::', actionPermission)
    SetSelectedInvID(id)
    setShowReminderModel(!showReminderModel)
    setReminderActionPermission(actionPermission)
  };

  const handleReminderModelClose = () => {
    setShowReminderModel(false);
  };

  const onReminderSuccess = () => {
    // console.log('onReminderSuccess :::::: ')
  }

  // Reminder Function End -------

  // Custom sort function for numeric values
  const numericSort = (a, b, order) => {
    if (order === 'asc') {
      return a - b; // Ascending order
    }
    return b - a; // Descending order
  };

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'investment_name', text: 'Investment Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
      headerWidth: '180px', wordWarp: true, hMinWidth: '170px', hMaxWidth: "180px", action: true, order: 'asc'
    },
    { dataField: 'asset_type', text: 'Product Type', hMinWidth: '120px', hMaxWidth: "140px", sort: true },
    { dataField: 'holding_company', text: 'Bank/Custodian', hMinWidth: '180px', hMaxWidth: "200px", sort: true },
    { dataField: 'first_activity_date', text: 'First Activity Date', hMinWidth: '140px', hMaxWidth: "170px", sort: true, isDateField: true },
    { dataField: 'current_value', text: 'Current Value ($)', sort: true, hMinWidth: '140px', hMaxWidth: "170px", isAmountField: true, sortFunc: numericSort },
    // { dataField: 'performance', text: 'Performance', sort: false, isPerformanceField: true },
    { dataField: 'ownership_percentage', text: 'Ownership Percentage (%)', hMinWidth: '130px', hMaxWidth: "140px", sort: true, isAmountField: true, sortFunc: numericSort },
    { dataField: 'cal_current_value', text: 'Current Ownership Value ($)', hMinWidth: '160px', hMaxWidth: "170px", sort: true, isAmountField: true, sortFunc: numericSort },
    { dataField: 'interest_earning_rate', text: 'Interest Earnings (%)', hMinWidth: '120px', hMaxWidth: "140px", sort: true, isAmountField: true, sortFunc: numericSort },
    { dataField: 'projected_annual_income', text: 'Cash Flow($)', hMinWidth: '140px', hMaxWidth: "170px", sort: true, isAmountField: true, sortFunc: numericSort },
  ];
  let tempAction = { dataField: 'Action', text: 'Action', classes: 'action-column', csvExport: false, standardAction: true, hMinWidth: '150px', handleOnClick: handleOnClickAction, tooltipSuffixText: " ", onClickParams: ['id', 'investment_name'], reminderCount: ['active_reminders_count'], setReminder: true }

  if (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) {
    columns.push(tempAction);
  } else {
    // if (!checkPermission(userPermissions, 'edit-stock')) tempAction.disableEdit = true;
    // if (!checkPermission(userPermissions, 'archive-stock')) tempAction.disableArchive = true;
    // if (!checkPermission(userPermissions, 'delete-stock')) tempAction.disableDelete = true;
    tempAction = { ...tempAction, disableDelete: true, disableEdit: true, disableArchive: true, setReminder: true }
    columns.push(tempAction);
  };

  const headerTotalValues = [
    { label: 'Total Investments ($)', value: headerValues.totalInvestments },
    { label: 'Total Current Value ($)', value: headerValues.totalCurrentValues },
    { label: 'Total Inv.Val after Debt ($)', value: headerValues.totalDebt },
  ];


  const updateReminderData = async () => {
    await fetchInvestmentList();
  }

  const handleTableChange = (type, value) => {
    try {
      switch (type) {
        case 'print':
          SetEnablePrint(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  const handlePrintClick = () => {
    confirmAlert({
      title: 'Alert',
      message: `For better viewing, please use landscape mode.`,
      buttons: [
        {
          label: 'Done',
          onClick: () => { SetEnablePrint(true); setLoading(true); }
        },
      ]
    });
  }

  return (
    <div className="main-content introduction-farm">
      <div className="col-12" style={{ padding: '5px 15px 15px 15px' }}>
        <div className="card">
          <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
          <div className="card-body card-breadcrumb">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                Back</a>
              {/* <div className="page-title-right title-prefix" >Liquid Assets</div> */}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}>Portfolio Summary
                  </a></li>
                  <li className="breadcrumb-item active" >Liquid Assets</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wraper-area">
        <div className="dashboard-area">
          <div className="container-fluid">
            <div className="row" style={{ alignItems: 'flex-end', alignItems: 'center' }} >

            </div>
            <DataChart
              header={"Liquid Assets"} data={chartData} labelKey={'asset_type'}
              seriesKey={["total_inv_aft_Debt", "total_invested"]} onChange={handleChartChange}
              seriesName={["Current Value", "Invested Value"]} type="bar" selectedData={selectedChartValue}
              filteredChartValue={filteredChartValue} removeChartIndex={removeChartIndex}
            />
            <br />
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title" style={{ marginBottom: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <h4 style={{ flex: 'auto' }}>Liquid Asset List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => { handlePrintClick() }} ></i>
                      </OverlayTrigger></h4>
                      {
                        // (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'create-stock')) &&
                        <button type="button" onClick={AddStock} className="btn btn-primary mb-2 me-2"><i className="icon_plus"></i> Add New</button>
                      }
                    </div>
                  </div>
                  <div >
                    <Datatable data={selectedInvList} columns={columns} headerName={'Liquid Asset List'} tableWidth={'100%'} pdfFile={'liquid_assest_list'} csvFileName={'Liquid Asset List'} actionPermissionPrefix={'stock'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} FreezeColumn={true} enablePrint={enablePrint} handleTableChange={handleTableChange} headerObjValue={headerTotalValues} showHeaderTotal={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PasswordConfirmation showViewModal={showPasswordModel} handleDelete={handleDelete} handleCloseViewModal={() => { setShowPasswordModel(false); revertDelete() }} />

      {/* <ReminderTaskModel show={showReminderTask} handleClose={handleClose} investmentID={getInvestmentID} handelListUpdate={handelListUpdate} />

      <ReminderList show={showReminderModel} recordId={selectedInvID} handleClose={handleReminderModelClose} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} onSuccess={onReminderSuccess} handelListUpdate={handelListUpdate} /> */}
      <ReminderTab show={showReminderTask} recordId={getInvestmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={handelListUpdate} invesmentCatId={category_id} entityType={'investment'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} actionPermission={reminderActionPermission} />

      {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
    </div>);
}

export default StockList;