
import React, { useState, useEffect, useContext } from 'react';

import UserListComponent from './UserListComponent';

function UserList() {
  
  return (
    <UserListComponent />
  );
}
export default UserList;