
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../Context/PageLoader';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { get, post, put, remove } from '../../api'
import { GET_OPERATING_BUSINESS, UPDATE_OPERATING_BUSINESS, CREATE_OPERATING_BUSINESS, OPERATING_BUSINESS_DELETE, GET_REMINDER_LIST, OPERATING_BUSINESS_ARCHIVE } from '../../api/endpoints'
import { addReminderLabel, checkPermission, concatUrlWithId, TitleTrim, ReminderTaskIcon } from '../../utils/commonUtils';
import { FormField, CurrencyField, TextAreaField, ViewDiv, SelectField, EINField } from '../../components/Forms/FormFields';
import { UserPermissionStore } from '../../Store/UserPermission';
import moment from 'moment';
import TabComponent from '../../components/TabComponent';
import ReminderTab from '../../components/Reminder/ReminderTab';
import { useReactToPrint } from 'react-to-print';
import PasswordConfirmation from '../../components/Forms/PasswordConfirmation';
import { exportOperatingBusiness } from '../../api/exportApi';

export const operatingBusinessSchema = Yup.object().shape({
    business_name: Yup.string()
        .label('Legal Entity Name').trim().required('Legal Entity Name is required'),
    primary_product_service: Yup.string().label('Primary Product/Service').trim().required('Primary Product/Service is required'),
    ein: Yup.string().matches(/^\d{2}-\d{7}$/, 'EIN must be in the format XX-XXXXXXX').nullable(true)
});


function OperatingBusinessForm({ props }) {

    const { businessID } = useParams();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        business_name: '',
        primary_product_service: '',
        managing_partner: '',
        percentage_interest: '',
        value: '',
        status: '',
        exit_strategy: '',
        home_address: '',
        key_emp_name: '',
        key_emp_title: '',
        total_liabilities: '',
        total_assets: '',
        number_of_employees: '',
        estimated_value: '',
        number_of_employees_entry: '',
        estimated_value_entry: '',
        value_entry: '',
        enable_reminder: false,
        reminder_date: "2024-01-01",
        reminder_status: null,
        record_reminder: false,
        document_reminder: false,
        url: '',
        state_country_of_incorporation: '',
        ein: '',
        contingent_liability: '',
        ownership_percentage: ''
    });
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const { loading, setLoading } = useContext(PageLoader);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [viewAction, setViewAction] = useState(true);
    const [previousPage, setPreviousPage] = useState('');
    const [previousUrl, setPreviousUrl] = useState('');
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [enableReminder, setEnableReminder] = useState(false);
    const [reminderStatus, setReminderStatus] = useState(false);
    const [snapReminderData, setSnapReminderData] = useState({ r_date: "", e_reminder: "", status: "" });
    const [reminderArr, setReminderArr] = useState({ record_rem_arr: [], document_rem_arr: [] });
    const [reminderDeletedIds, setReminderDeletedIds] = useState([]);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [reminderTaskCount, setReminderTaskCount] = useState(0);
    const [printMode, setPrintMode] = useState(false);
    const [showErrorIcon, setShowErrorIcon] = useState(false);
    const [showPasswordModel, setShowPasswordModel] = useState(false);
    const [deleteItem, setDeleteItem] = useState({ id: '', name: '' })
    const statusType = [
        { value: true, label: 'Active' },
        { value: false, label: 'Deactivate' },
    ];
    const [BackupBeforeDelete, setBackupBeforeDelete] = useState(false);



    function ChangeEditmode(id) {
        setLoading(true);
        history(`/edit-operating-business/${businessID}`, { state: { previousPage: "operating Business View", previousUrl: `/view-operating-business/${businessID}` } });
    }
    const revertDelete = () => {
        setDeleteItem({ id: '', name: '' })
    }
    // function deleteMineralAsset(id, Name) {
    //     confirmAlert({
    //         title: 'Delete',
    //         message: `Are you sure you want to delete the ${Name}?`,
    //         buttons: [
    //             {
    //                 label: 'Yes',
    //                 // onClick: () => handleDelete(id, Name)
    //                 onClick: () => {
    //                     setDeleteItem({ id: id, name: Name });
    //                     setShowPasswordModel(true)
    //                 }
    //             },
    //             {
    //                 label: 'Backup and Delete',
    //                 // onClick: () => handleDelete(id, Name)
    //                 onClick: () => {
    //                     setDeleteItem({ id: id, name: Name });
    //                     setShowPasswordModel(true);
    //                     setBackupBeforeDelete(true);
    //                 }
    //             },
    //             {
    //                 label: 'No',
    //                 onClick: revertDelete()
    //             }
    //         ]
    //     });

    // }
    function deleteMineralAsset(id, Name) {
        confirmAlert({
            title: 'Delete',
            message: `Are you sure you want to delete the ${Name}?`,
            overlayClassName: 'custom-overlay',

            customUI: ({ title, message, onClose }) => (
                <div className="custom-popup">
                    <h2>{title}</h2>
                    <p>{message}</p>
                    <div className="button-group">
                        <button
                            className="confirm-button confirm-yes"
                            onClick={() => {
                                setDeleteItem({ id: id, name: Name });
                                setShowPasswordModel(true);
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="confirm-button confirm-backup"
                            onClick={() => {
                                setDeleteItem({ id: id, name: Name });
                                setShowPasswordModel(true);
                                setBackupBeforeDelete(true);
                                onClose();
                            }}
                        >
                            Backup & Delete
                        </button>
                        <button className="confirm-button confirm-no" onClick={onClose}>
                            No
                        </button>
                    </div>
                </div>
            )
        });

    }

    const handleBackClick = (businessID, pageUrl) => {
        if (pageUrl) {
            history(pageUrl);
        }
        else {
            history('/operating-business');
        }
    };

    const downloadAndProcessDelete = async () => {
        try {
            let result = await exportOperatingBusiness('value', initialValues);
            console.log('Result value :: ', result);
            return result;
        } catch (error) {
            console.log('Error in the downloadAndProcedDelete : ', error);
        }
    }
    const handleDelete = async () => {
        const { id, name } = deleteItem;
        try {
            setLoading(true)
            let isBackup = true
            if (BackupBeforeDelete) {
                isBackup = await downloadAndProcessDelete();
            }
            if (isBackup) {
                const deleteInvestment = await remove(concatUrlWithId(OPERATING_BUSINESS_DELETE, id));
                if (deleteInvestment) {
                    setShowPasswordModel(false);
                    history('/operating-business')
                }
                setBackupBeforeDelete(false)
                setShowPasswordModel(false)
                notify(`${name} successfully deleted`, 'success')
            } else {
                notify(`${name} backup Failed: We couldn't complete the backup. Please try again.`, 'warning')
                setLoading(false)
            }
        } catch (error) {
            setShowPasswordModel(false);
            setLoading(false)
            console.log('Error while deleting the error : ', error)
            notify(`Failed to delete ${name}`, 'error')
        }

    };

    const notify = (message, type) => toast(message, { type });

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);

        if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager') || checkPermission(tempPermission, 'view-operating-business')) {
            const fetchInvestmentById = async () => {
                if (location.state) {
                    setPreviousPage(location.state.previousPage)
                    setPreviousUrl(location.state.previousUrl)
                } else {
                    setLoading(false);
                }
                if ((urlName == 'edit-operating-business') || (urlName == 'view-operating-business')) {

                    try {
                        setLoading(true);
                        const response = await get(concatUrlWithId(GET_OPERATING_BUSINESS, businessID));
                        if (response) {
                            let values = {
                                ...response
                            };

                            setSelectedType(values.type);

                            setInitialValues({ ...values, reminder_date: values.reminder_date ? moment(values.reminder_date).format('YYYY-MM-DD') : '2024-01-01' });
                            setReminderStatus(values.reminder_status || null);
                            setEnableReminder(values.enable_reminder || false);
                            setSnapReminderData({ r_date: values.reminder_date ? moment(values.reminder_date).format('YYYY-MM-DD') : '', e_reminder: values.enable_reminder, status: values.status })

                            let tempRecRemArr = addReminderLabel(values['record_reminder_arr'])
                            let tempDocRemArr = addReminderLabel(values['document_reminder_arr'])
                            setReminderArr({ record_rem_arr: tempRecRemArr, document_rem_arr: tempDocRemArr })

                            if (response.permission_keys?.length && response.permission_keys?.[0]?.permission_keys) {
                                setUserPermissions(response.permission_keys[0].permission_keys)
                            }
                        }
                        getReminderTaskCount();

                        if (response.is_archived == true) {
                            setViewAction(false)
                            setPreviousPage('Archived List')
                            setPreviousUrl('/archived-list')
                        }
                    } catch (error) {
                        window.scrollTo(0, 1);
                        if (error?.response?.data?.message) {
                            notify(`${error?.response?.data?.message}`, 'error')
                            history('/portfolio-summary')
                        } else {
                            notify(`${error}`, 'error')
                            console.log('Error while Fecting the error : ', error)
                        }

                    } finally {
                        window.scrollTo(0, 1);
                        setLoading(false);
                    }
                } else {
                    window.scrollTo(0, 1);
                    setLoading(false);
                }
            }

            fetchInvestmentById();
            if (urlName == 'view-operating-business') {
                setViewMode(true)
                setEditMode(false);
                setViewAction(true);
                setPreviousPage('Operating Business List')
                setPreviousUrl('/operating-business')
                // if((!checkPermission(userPermissions, 'isAdmin') && !checkPermission(userPermissions, 'isManager')) ){
                //     console.log('check=========',checkPermission(userPermissions, 'view-stock'),userPermissions);
                //     if(!checkPermission(userPermissions, 'view-mineral-asset') ){
                //         notify("Sorry, you do not have permission to access this.", 'warning')
                //         history('/portfolio-summary')
                //     }
                // }
            } else if (urlName == 'edit-operating-business') {
                setViewMode(false)
                setEditMode(true)
                setViewAction(false)
                setPreviousPage('Operating Business List')
                setPreviousUrl('/operating-business')
                // if((!checkPermission(userPermissions, 'isAdmin') && !checkPermission(userPermissions, 'isManager')) ){
                //     if(!checkPermission(userPermissions, 'view-mineral-asset') ){
                //         notify("Sorry, you do not have permission to access this1.", 'warning')
                //         history('/portfolio-summary')
                //     }else if(!checkPermission(userPermissions, 'edit-mineral-asset') ){
                //         notify("Sorry, you do not have permission to access this.", 'warning')
                //         history('/stock-list')
                //     }
                // }
            } else {
                setViewMode(false)
                setEditMode(false)
                setViewAction(false)
                setPreviousPage('Operating Business List')
                setPreviousUrl('/operating-business')
            }
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        // Auth check and update
        authCheckAndUpdate();

    }, [location.state]);

    const getReminderTaskCount = async () => {

        let payload = {
            investment_id: businessID
        };
        const reminderTasks = await post(GET_REMINDER_LIST, payload);

        if (reminderTasks?.data?.reminderList?.length) {
            setReminderTaskCount(reminderTasks?.data?.reminderList?.length)
        } else {
            setReminderTaskCount(0)
        }
    }

    const updateReminderData = async () => {
        await getReminderTaskCount();
    }

    const handleBeforePrint = () => {
        setPrintMode(true);
    };

    const handleAfterPrint = () => {
        setPrintMode(false);
        setLoading(false);
    };

    const handlePrint = useReactToPrint({
        content: () => formRef.current,
        onBeforeGetContent: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        documentTitle: initialValues.business_name || 'investment'
    });

    const handleChangePrint = () => {
        confirmAlert({
            title: 'Alert',
            message: `For better viewing, please use landscape mode.`,
            buttons: [
                {
                    label: 'Done',
                    onClick: () => { setPrintMode(true); setLoading(true) }
                },
            ]
        });
    }

    const onDataLoadingComplete = () => {
        setLoading(false);
        handlePrint()
    }

    useEffect(() => {
        if (printMode) {
            setTimeout(() => {
                handlePrint()
            }, 100);
        };
    }, [printMode])

    const checkValidation = () => {
        setShowErrorIcon(true);
    }


    const archivedOperatingBusiness = (investment_id, investment_name) => {
        confirmAlert({
            title: 'Archive',
            message: `Are you sure want to Archive ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleArchived(investment_id, investment_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const handleArchived = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const archive = await post(OPERATING_BUSINESS_ARCHIVE, { is_archived: true, id: investment_id });
            if (archive) {
                history('/operating-business')
            }
            notify(`${investment_name} has been archived.`, 'warning',)
        } catch (error) {
            console.log('Error while handleArchived the error : ', error)
            notify(`Unable to archive ${investment_name}.`, 'warning',)
        }
    };

    return (
        <>
            <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
            <div className="container-fluid" style={{ marginTop: '20px' }}>
                <div className="col-12" style={{ padding: '15px' }}>
                    <div className="card">
                        <div className="card-body card-breadcrumb">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                                    Back</a>
                                {<div className="page-title-right title-prefix" >
                                    {`${editMode ? 'Edit -' : (viewMode ? 'View -' : '')}`} <TitleTrim title={initialValues.business_name} />
                                </div>}
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)}>{previousPage}
                                        </a></li>
                                        <li className="breadcrumb-item active" >{editMode ? 'Edit Operating Business' : (viewMode ? 'View Operating Business' : 'Add Operating Business')}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={formRef}>
                {((editMode && initialValues?.id) || (viewMode && initialValues?.id) || !(viewMode || editMode)) && <Formik

                    initialValues={initialValues}
                    validationSchema={operatingBusinessSchema}
                    onSubmit={async (values, actions) => {
                        setIsSubmitting(true);
                        try {
                            if (values.record_reminder && (!reminderArr.record_rem_arr?.[0]?.reminder_type
                                || (reminderArr.record_rem_arr?.[0]?.reminder_type != 'daily' && !reminderArr.record_rem_arr?.[0]?.reminder_value))) {
                                if (!reminderArr.record_rem_arr?.[0]?.reminder_type) {
                                    notify(`Occurrence is required `, 'warning')
                                } else if (reminderArr.record_rem_arr?.[0]?.reminder_type != 'daily' && !reminderArr.record_rem_arr?.[0]?.reminder_value) {
                                    notify(`On is required `, 'warning')
                                }

                                setIsSubmitting(false);
                                return;
                            }

                            // Document reminder validation
                            let tempDocumentArr = []
                            if (reminderArr?.document_rem_arr?.length) {
                                tempDocumentArr = reminderArr?.document_rem_arr.filter((doc) => {
                                    return doc.reminder_type && doc.document_type && (doc.reminder_type == 'daily' || doc.reminder_value);
                                });
                            };

                            setReminderArr({ ...reminderArr, document_rem_arr: tempDocumentArr })

                            let response = []
                            const url = editMode ? UPDATE_OPERATING_BUSINESS : CREATE_OPERATING_BUSINESS;
                            const bodyPayload = {
                                business_name: values.business_name,
                                primary_product_service: values.primary_product_service,
                                managing_partner: values.managing_partner,
                                percentage_interest: values.percentage_interest,
                                value: values.value,
                                exit_strategy: values.exit_strategy,
                                home_address: values.home_address,
                                key_emp_name: values.key_emp_name,
                                key_emp_title: values.key_emp_title,
                                total_liabilities: values.total_liabilities,
                                contingent_liability: values.contingent_liability,
                                total_assets: values.total_assets,
                                number_of_employees: values.number_of_employees,
                                estimated_value: values.estimated_value,
                                status: values.status,
                                enable_reminder: values.enable_reminder,
                                reminder_date: values.reminder_date,
                                reminder_modified: editMode ? (snapReminderData.status !== values.status) || (snapReminderData.e_reminder !== values.enable_reminder) ||
                                    (snapReminderData.r_date && snapReminderData.r_date !== moment(values.reminder_date).format('YYYY-MM-DD')) : false,
                                record_reminder: values.record_reminder,
                                document_reminder: values.document_reminder,
                                record_reminder_arr: reminderArr.record_rem_arr,
                                document_reminder_arr: tempDocumentArr || reminderArr.document_rem_arr,
                                reminder_deleted_ids: reminderDeletedIds,
                                url: values.url,
                                quarter1_pd: values.quarter1_pd,
                                quarter2_pd: values.quarter2_pd,
                                quarter3_pd: values.quarter3_pd,
                                quarter4_pd: values.quarter4_pd,
                                annual_salary_fees: values.annual_salary_fees,
                                ownership_percentage: values.ownership_percentage || '',
                                state_country_of_incorporation: values.state_country_of_incorporation,
                                ein: values.ein
                            };

                            if (editMode) {
                                bodyPayload.id = values.id;
                            }

                            response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);

                            if (response) {

                                notify(`Operating Business ${values.business_name} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')

                                if (isSaveNew == true) {
                                    actions.resetForm();
                                    setShowErrorIcon(false)
                                    setIsFieldClear((prevState) => prevState ? prevState + 1 : 1);
                                    setEnableReminder(false);
                                } else {
                                    if (previousUrl) {
                                        history(previousUrl);
                                    } else {
                                        history('/operating-business', { state: { status: editMode ? "update" : "create" } });
                                        setLoading(true);
                                    }
                                }
                            } else {
                                editMode ? notify(`operating Business update failed...`, 'error') : notify(`Create operating Business failed...`, 'error');
                            }
                        } catch (error) {
                            notify(error?.response?.data?.message, 'error');
                            console.error('API Error:', error);
                        }
                        setIsSubmitting(false);
                    }}
                    enableReinitialize
                >
                    {props => (
                        <div className="main-content introduction-farm">
                            <div className="content-wraper-area">
                                <div className="dashboard-area">
                                    <div className="container-fluid">
                                        <div className="card">
                                            <div className={`card-body ${viewMode ? 'view-mode' : 'add-mode'}`}>
                                                {!viewAction && viewMode && <div className='no-print' style={{ textAlign: 'center' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                            <i className="fa fa-print btn-circle print-btn" onClick={() => { handleChangePrint() }} ></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                }
                                                {viewAction && viewMode && <div style={{ textAlign: 'center' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                            <i className="fa fa-print btn-circle print-btn no-print" onClick={() => { handleChangePrint() }} ></i>
                                                        </OverlayTrigger>
                                                        {<ReminderTaskIcon
                                                            iconClass="no-print "
                                                            tooltip={`Set/View Reminders `}
                                                            onClick={() => setShowReminderTask(true)}
                                                            backgroundColor="#8098ad"
                                                            count={reminderTaskCount}
                                                        />}

                                                        {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'edit-operating-business')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit </Tooltip>} >
                                                            <i className="bx bxs-edit btn btn-circle no-print" onClick={() => ChangeEditmode(initialValues?.id)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                        </OverlayTrigger>}
                                                        {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'archive-operating-business')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Archive</Tooltip>} >
                                                            <i className="zmdi zmdi-archive btn btn-circle" onClick={() => archivedOperatingBusiness(initialValues?.id, initialValues?.business_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#74788d', cursor: 'pointer' }} ></i>
                                                        </OverlayTrigger>}

                                                        {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'delete-operating-business')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Delete </Tooltip>} >
                                                            <i className="bx bx-trash btn btn-circle no-print" onClick={() => deleteMineralAsset(initialValues?.id, initialValues?.business_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#dd4a57', cursor: 'pointer' }}></i>
                                                        </OverlayTrigger>}
                                                    </div>
                                                </div>
                                                }
                                                <Form>
                                                    {printMode && <div className="card-title only-visible-in-print" style={{ marginBottom: '5px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                            <h4 className='heading-print text-bold' style={{ wordWrap: 'break-word', maxWidth: '300px' }}>Operating Business - {initialValues.business_name}</h4>
                                                        </div>
                                                    </div>}
                                                    <div className='row print-font-header'>
                                                        {!viewMode && <div className='col-lg-6'>
                                                            <fieldset>
                                                                <legend className='text-bold'> Operating Business </legend>

                                                                <FormField viewMode={viewMode} fieldName="business_name" label="Legal Entity Name" value={initialValues.business_name} maxLength={200} />

                                                                <EINField viewMode={viewMode} fieldName="ein" label="EIN" value={initialValues.ein} maxLength={10} isRequired={false} />

                                                                <FormField viewMode={viewMode} fieldName="primary_product_service" label="Primary Product/Service" value={initialValues.primary_product_service} maxLength={200} />

                                                                {editMode ? <SelectField fieldName="status" label="Status" value={initialValues.status} options={statusType} values={props.values} setFieldValue={props.setFieldValue} /> : ''}

                                                                <FormField viewMode={viewMode} fieldName="managing_partner" label="Managing Partner" value={initialValues.managing_partner} maxLength={200} isRequired={false} />

                                                                <CurrencyField viewMode={viewMode} fieldName="percentage_interest" label="Percentage Interest(%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} disableNegative={true} />

                                                                <CurrencyField viewMode={viewMode} fieldName="value" label="Value" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />

                                                                <TextAreaField viewMode={viewMode} fieldName="home_address" label="Home Address" value={initialValues.home_address} maxLength={500} textAlign={true} />

                                                                <TextAreaField viewMode={viewMode} fieldName="url" label="URL" value={initialValues.url} maxLength={2000} isRequired={false} />

                                                                <FormField viewMode={viewMode} fieldName="state_country_of_incorporation" label="State/Country of Incorporation" value={initialValues.state_country_of_incorporation} maxLength={200} isRequired={false} />

                                                            </fieldset>
                                                        </div>}
                                                        {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                            <div>
                                                                <legend className='text-bold lenged-view'> Operating Business Information </legend>
                                                                <ViewDiv fieldLabel={"Legal Entity Name"} fieldValue={initialValues.business_name} type={"text"} />
                                                                <ViewDiv fieldLabel={"EIN"} fieldValue={initialValues.ein} type={"text"} />
                                                                <ViewDiv fieldLabel={"Primary Product/Service"} fieldValue={initialValues.primary_product_service} type={"text"} />
                                                                <ViewDiv fieldLabel={"Status"} fieldValue={initialValues.status ? "Active" : "Deactivate"} type={"text"} />
                                                                <ViewDiv fieldLabel={"Managing Partner"} fieldValue={initialValues.managing_partner} type={"text"} />
                                                                <ViewDiv fieldLabel={"Percentage Interest(%)"} fieldValue={initialValues.percentage_interest} type={"text"} />
                                                                <ViewDiv fieldLabel={"Value($)"} fieldValue={initialValues.value} type={"currency"} logValue={initialValues.value_entry} />
                                                                <ViewDiv fieldLabel={"Goals/Exit Strategy"} fieldValue={initialValues.exit_strategy} type={"text"} />
                                                                <ViewDiv fieldLabel={"Home Address"} fieldValue={initialValues.home_address} type={"text"} />
                                                                <ViewDiv fieldLabel={"URL"} fieldValue={initialValues.url} type={"url"} />
                                                                <ViewDiv fieldLabel={"State/Country of Incorporation"} fieldValue={initialValues.state_country_of_incorporation} type={"text"} />

                                                                <legend className='text-bold lenged-view'>Cash Flow</legend>
                                                                <ViewDiv fieldLabel={"Annual Salary/Fees($)"} fieldValue={initialValues.annual_salary_fees} type={'currency'} />
                                                                <ViewDiv fieldLabel={"1 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter1_pd} type={'currency'} />
                                                                <ViewDiv fieldLabel={"2 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter2_pd} type={'currency'} />
                                                                <ViewDiv fieldLabel={"3 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter3_pd} type={'currency'} />
                                                                <ViewDiv fieldLabel={"4 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter4_pd} type={'currency'} />

                                                            </div>
                                                        </div>}

                                                        {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                            <div>
                                                                <legend className='text-bold lenged-view'>Key Employees</legend>
                                                                <ViewDiv fieldLabel={"Name"} fieldValue={initialValues.key_emp_name} type={"text"} />
                                                                <ViewDiv fieldLabel={"Title"} fieldValue={initialValues.key_emp_title} type={"text"} />

                                                            </div>
                                                            <div>
                                                                <legend className='text-bold lenged-view'> Yearly Details </legend>
                                                                <ViewDiv fieldLabel={"Estimated value($)"} fieldValue={initialValues.estimated_value} type={"currency"} logValue={initialValues.estimated_value_entry} />
                                                                <ViewDiv fieldLabel={"Number of Employees"} fieldValue={initialValues.number_of_employees} type={"text"} logValue={initialValues.number_of_employees_entry} />
                                                                <ViewDiv fieldLabel={"Total Assets($)"} fieldValue={initialValues.total_assets} type={"currency"} />
                                                                <ViewDiv fieldLabel={"Total Liabilities($)"} fieldValue={initialValues.total_liabilities} type={"currency"} extra={true} depValue={initialValues.contingent_liability} />
                                                                <ViewDiv fieldLabel={"Ownership Percentage(%)"} fieldValue={initialValues.ownership_percentage || ""} type={"text"} />
                                                                {/* <ViewDiv fieldLabel={"Do you want to set reminder for yearly details?"} fieldValue={initialValues.enable_reminder ? 'Yes' : 'No'} type={"text"} />
                                                            {initialValues.enable_reminder ? <ViewDiv fieldLabel={"Reminder Date"} fieldValue={initialValues.reminder_date} type={"date"} /> : ''} */}

                                                                <legend className='text-bold lenged-view'> System Information</legend>
                                                                <ViewDiv fieldLabel={"Created At"} fieldValue={initialValues.createdAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                                <ViewDiv fieldLabel={"Last Modified At"} fieldValue={initialValues.updatedAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                                <ViewDiv fieldLabel={"Created By"} fieldValue={initialValues['operating_business_created_by_fk.full_name']} type={"text"} />
                                                                <ViewDiv fieldLabel={"Last Modified By"} fieldValue={initialValues['operating_business_updated_by_fk.full_name']} type={"text"} />

                                                            </div>
                                                        </div>}

                                                        {!viewMode && <div className='col-lg-6'>
                                                            <fieldset>
                                                                <legend className='text-bold'>Additional Information</legend>

                                                                <TextAreaField viewMode={viewMode} fieldName="exit_strategy" label="Goals/Exit Strategy" value={initialValues.exit_strategy} maxLength={500} textAlign={true} />

                                                            </fieldset>
                                                            <fieldset>
                                                                <legend className='text-bold'>Key Employees </legend>

                                                                <FormField viewMode={viewMode} fieldName="key_emp_name" label="Name" value={initialValues.key_emp_name} maxLength={200} isRequired={false} />
                                                                <FormField viewMode={viewMode} fieldName="key_emp_title" label="Title" value={initialValues.key_emp_title} maxLength={200} isRequired={false} />

                                                            </fieldset>
                                                            <fieldset>
                                                                <legend className='text-bold'>Yearly Details </legend>
                                                                <CurrencyField fieldName="estimated_value" label="Estimated Value" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />
                                                                <FormField viewMode={viewMode} fieldName="number_of_employees" label="Number of Employees" value={initialValues.number_of_employees} maxLength={16} type="number" isRequired={false} />
                                                                <CurrencyField fieldName="total_assets" label="Total Assets" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />
                                                                <div className='row'>
                                                                    <div className='col-lg-6'>
                                                                        <CurrencyField fieldName="total_liabilities" label="Total Liabilities" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <CurrencyField viewMode={viewMode} fieldName="contingent_liability" label="Contingent Liability(%)" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} prefix='' />
                                                                    </div>
                                                                </div>
                                                                <CurrencyField viewMode={viewMode} fieldName="ownership_percentage" label="Ownership Percentage(%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} disableNegative={true} />

                                                                {/* <SwitchField fieldName="enable_reminder" label="Do you want to set reminder for yearly details?" viewMode={viewMode} values={props.values} setFieldValue={(field, val) => {
                                                                props.setFieldValue('enable_reminder', val);
                                                                handleReminderChange(val);
                                                            }} />
                                                            {enableReminder ?
                                                                <DatePickerField fieldName="reminder_date" label="Reminder Date" value={initialValues.reminder_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear}
                                                                    isDateMonth={true} handleDateFieldChange={handleDateFieldChange} isRequired={false} /> : ''} */}
                                                            </fieldset>
                                                        </div>}
                                                        {!viewMode && <div className='col-lg-12'>

                                                            <fieldset>
                                                                <legend className='text-bold'> Cash Flow </legend>
                                                                <div className='row'>
                                                                    <div className='col-lg-6'>
                                                                        <CurrencyField viewMode={viewMode} fieldName="annual_salary_fees" label="Annual Salary/Fees" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                        <CurrencyField viewMode={viewMode} fieldName="quarter1_pd" label="1st Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                        <CurrencyField viewMode={viewMode} fieldName="quarter3_pd" label="3rd Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <CurrencyField viewMode={viewMode} fieldName="quarter2_pd" label="2nd Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                        <CurrencyField viewMode={viewMode} fieldName="quarter4_pd" label="4th Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                gap: '10px'
                                                            }}>
                                                                {!viewMode && showErrorIcon && !props.isValid && <><OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Please find the page error</Tooltip>} >
                                                                    <i className="zmdi zmdi-block-alt" style={{ fontSize: '19px', margin: '0px 5px', padding: '8px 0px 0px 0px', color: 'red', cursor: 'pointer' }} ></i>
                                                                </OverlayTrigger></>}
                                                                {/* {!viewMode && <button type="reset" onClick={() => history('/operating-business-list')} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>} */
                                                                    !viewMode && <button type="reset" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>}

                                                                {!viewMode && <button type="submit" onClick={() => { setIsSaveNew(false); checkValidation(); }} disabled={isSubmitting} className="btn btn-primary w-md">{editMode ? 'Update' : 'Save'}</button>}
                                                                {editMode != true && (
                                                                    !viewMode && <button type="submit" onClick={() => { setIsSaveNew(true); checkValidation(); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</button>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {viewMode && userPermissions?.length && !printMode && <TabComponent investmentId={businessID} stockInvId={''} permissions={userPermissions} viewAction={viewAction}
                                    uploadPermission={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'upload-operating-business')}
                                    isArchived={!viewAction} isStockView={false} hideAuditLog={true} isOperatingBusiness={true} documentTypeKey={'Operating Business'} />}
                            </div>
                            {/* <div className="card-body">
                            <div className='card'>
                                <div className='card-body'>
                                    {viewMode && <Documents uploadPermission={true} isArchived={false} isOperatingBusiness={true} businessID={businessID} documentTypeKey={'Operating Business'} />}
                                </div>
                            </div>
                        </div> */}
                            {/* {(userPermissions?.length && checkPermission(userPermissions, 'isManager') && (editMode || viewMode)) && <Footer PropUserPermissions={userPermissions} />} */}
                        </div >

                    )
                    }
                </Formik >
                }
            </div>
            <PasswordConfirmation showViewModal={showPasswordModel} handleDelete={handleDelete} handleCloseViewModal={() => { setShowPasswordModel(false); revertDelete() }} />

            {/* <ReminderTaskModel show={showReminderTask} handleClose={() => setShowReminderTask(false)} investmentID={businessID} handelListUpdate={() => getReminderTaskCount()} />
            <ReminderList show={showReminderModel} recordId={businessID} handleClose={setShowReminderModel} entityType={'operating_business'} headerName={'Reminder'} invesmentCat={''} handelListUpdate={getReminderTaskCount} /> */}
            <ReminderTab show={showReminderTask} recordId={businessID} handleClose={() => setShowReminderTask(false)} hangleTabChange={getReminderTaskCount} invesmentCatId={''} entityType={'operating_business'}
                actionPermission={(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'edit-operating-business'))} />

        </>
    );
}
export default OperatingBusinessForm;