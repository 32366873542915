import { put } from '.';
import { UPDATE_INVESTMENT_TAX_CHECK } from './endpoints';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { concatUrlWithId} from '../utils/commonUtils';

const notify = (message, type) => toast(message, { type });

export const updateTaxValByInvId = async (inv_id, value) => {
    try {
        const updateTax = await put(concatUrlWithId(UPDATE_INVESTMENT_TAX_CHECK, inv_id), { excluded_taxable_estate: value });
        if (!updateTax.success)
            notify(`Update failed. Please try again later.`, 'error')
    } catch (error) {
        console.log('Error in the following function:: ', error)
    }
};