// Liquid Assest Header excel

export const LiquidAssestHeader = {
    investment_name: "Investment Name",
    first_activity_date: "First Activity Date",
    'stock_investment_details.asset_type': "Product Type",
    'stock_investment_details.stock_name': "Stock Symbol",
    'stock_investment_details.stock_account_number': "Account Number",
    'stock_investment_details.no_of_shares': "No of Shares",
    // investment_value: "",
    amount_borrowed: "Amount Borrowed($)",
    amount_invested: "Amount Invested($)",
    current_value: "Current Value($)",
    'stock_investment_details.interest_earning_rate': "Interest Earning Rate(%)",
    'stock_investment_details.interest_earned': "Interest Earned($)",
    'stock_investment_details.broker': "Broker",
    'stock_investment_details.holding_company': "Bank/Custodian",
    'stock_investment_details.performance': "performance",
    // auditLog: "",
    // investmentAuditLog: "",
    maturity_date: "Maturity Date",
    'stock_investment_details.account_type': "Account Type",
    'stock_investment_details.promissory_note': "Promissory Note",
    'stock_investment_details.ownership_percentage': "Ownership Percentage(%)",
    excluded_taxable_estate: "Excluded from Taxable Estate",
    createdAt: "Created At",
    updatedAt: "Last Modified At",
    note: "Notes",
    'investment_headers_created_by.full_name': "Created By",
    'investment_headers_modified_by.full_name': "Last Modified By",
}

export const ProfessionalContactHeader = {
    contact_type: "Contact Type",
    name: "Name",
    email: "Email",
    phone_number: "Phone Number"
}

export const InvestmentEarnedHeader = {
    interest_earned: "Interest Earned ($)",
    interest_earned_date: "Interest Earned Date",
    'stock_interest_created_by.full_name': "Created By",
    'stock_interest_modified_by.full_name': "Updated By",
    'createdAt': "Created By",
    'updatedAt': "Last Modified By",
}

// Liquid Assest Header excel End

// RealEstate stock holder Start
export const RealEstateHoldingsHeader = {
    lender_name: "Lender Name",
    loan_number: "Loan Number",
    investment_date: "Investment Date",
    amount_invested: "Total Invested($)",
    amount_borrowed: "Mortgage/Bank Debt($)",
    current_value: "Current Valuation($)",
    cal_current_value: "Investment Value after Debt($)",
    ownership_percentage: "Ownership Percentage(%)",
    class_a_members: "Class A Members",
    cash_distributions: "Cash Distributions",
    carrier: "Carrier",
    policy_number: "Policy Number",
    amount_insured: "Amount Insured($)",
    expiration_date: "Expiration Date",
    premium: "Premium($)",
    createdAt: "Created At",
    updatedAt: "Last Modified At",
    'investment_headers_created_by.full_name': "Created By",
    'investment_headers_modified_by.full_name': "Last Modified By",
    investment_name: "Legal Entity Name",
    ein: "EIN",
    state_country_of_incorporation: "State/Country of Incorporation",
    property_type: "Property Type",
    property_tax_id: "Property Tax ID",
    building_square_feet: "Building Square Feet",
    parcel_size_acreage: "Parcel Size/Acreage",
    property_address: "Property Address",
    manager: "Managing Partner or President",
    state_of_formation: "State of Formation",
    year_built: "Year Built",
    url: "URL",
    carrier: "Carrier",
    policy_number: "Policy Number",
    amount_insured: "Amount Insured($)",
    expiration_date: "Expiration Date",
    premium: "Premium($)",
    exit_strategy: "Exit Strategy/Payout Details",
    quarter1_pd: "1 st Quarter Projected Distributions($)",
    quarter2_pd: "2 st Quarter Projected Distributions($)",
    quarter3_pd: "3 st Quarter Projected Distributions($)",
    quarter4_pd: "4 st Quarter Projected Distributions($)",
};

export const IncomeExpenseHeader = {
    amount: "Amount($)",
    comments: 'Comments',
    financial_activities: "Financial Activity",
    frequency_start: "Frequency Start",
    frequency_type: "Frequency",
    projected_amount: "Projected Amount($)",
    year_upto: "Year Upto",
    created_year: 'Created Year',
    createdAt: "Created At",
    updatedAt: "Last Modified At",
    'investment_headers_created_by.full_name': "Created By",
    'investment_headers_modified_by.full_name': "Last Modified By",
}

export const TenetHeader = {
    monthly_payment: "Monthly Payment($)",
    notes: 'Notes',
    rent_increase_start_date: "Next Rent Increase Start Date",
    rented_square_feet: "Rented Square Feet",
    suite_number: "Suite Number",
    tenant_contact_email: "Tenant Contact Email",
    tenant_contact_number: "Tenant Contact Number",
    tenant_contact_name: 'Tenant Contact Name',
    tenant_name: 'Tenant Name',
    createdAt: "Created At",
    updatedAt: "Last Modified At",
    lease_start_date: "Lease Start Date",
    lease_termination_date: "Lease Termination Date",
    'investment_headers_created_by.full_name': "Created By",
    'investment_headers_modified_by.full_name': "Last Modified By",
}

// RealEstate stock holder End

// Private Equity Start
export const PrivateEquityHeader = {
    investment_name: 'Legal Entity Name',
    // is_active: 'Achived',
    amount_invested: "Total Purchase Price($)",
    amount_borrowed: "Mortgage/Bank Debt($)",
    // investment_category: process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID,
    // fixed_income: false,
    note: 'Notes',
    // is_active: '',
    property_address: 'Property Address',
    property_type: 'Product Type',
    investment_date: 'Investment Date',
    investment: 'Investment(%)',
    broker: 'Broker',
    current_value: 'Current Valuation($)',
    manager_name: 'Manager Name',
    contact_person_name: 'Contact Person Name',
    contact_person_mobile_number: 'Contact Person Mobile Number',
    contact_person_address: 'Contact Person Address',
    exit_strategy: 'Exit Strategy/Payout Details"',
    // current_value: 'Current Valuation($)',
    // image: '',
    // property_image_remove: false,
    // is_archived: '',
    state_of_formation: 'State of Formation',
    ownership_percentage: 'Ownership Percentage(%)',
    preferred_return_interest: 'Preferred Return/Interest(%)',
    equity_debt_invested: 'Equity/Debt Invested',
    url: 'URL',
    state_country_of_incorporation: 'State/Country of Incorporation',
    ein: "EIN",
    quarter1_pd: "1 st Quarter Projected Distributions($)",
    quarter2_pd: "2 st Quarter Projected Distributions($)",
    quarter3_pd: "3 st Quarter Projected Distributions($)",
    quarter4_pd: "4 st Quarter Projected Distributions($)",
    cal_current_value: 'Investment Value after Debt($)',
    contingent_liability: 'Contingent Liability(%)',
    company_investing: 'Company Investing',
    'investment_headers_created_by.full_name': "Created By",
    'investment_headers_modified_by.full_name': "Last Modified By",
    // excluded_taxable_estate: false
}
// Private Equity End


//Mineral Asset (Royalty Income) Start
export const MineralAssertHeader = {
    investment_name: 'Legal Entity Name',
    amount_invested: "Amount Invested($)",
    amount_borrowed: "Mortgage/Bank Debt($)",
    // is_active: true,
    // investment_category: process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID,
    // investment_value: '',
    current_value: 'Current Value($)',
    // fixed_income: false,
    note: 'Notes',
    // is_active: '',
    type: 'Type',
    leased: 'Leased or Licensed',
    operator: 'Lessee',
    lease_date: 'Leased/Licensed Date',
    total_acreage: 'Total Acreage',
    percentage_interest: 'Percentage Interest(%)',
    royalty_percentage: 'Royalty Percentage(%)',
    state_country_of_incorporation: 'State/Country of Incorporation',
    ein: 'EIN',
    contingent_liability: 'Contingent Liability(%)',
    excluded_taxable_estate: 'Excluded from Taxable Estate',
    'investment_headers_created_by.full_name': "Created By",
    'investment_headers_modified_by.full_name': "Last Modified By",
    quarter1_pd: "1 st Quarter Projected Distributions($)",
    quarter2_pd: "2 st Quarter Projected Distributions($)",
    quarter3_pd: "3 st Quarter Projected Distributions($)",
    quarter4_pd: "4 st Quarter Projected Distributions($)",
    // excluded_taxable_estate: false
}
//Mineral Asset (Royalty Income) End

//PersonalAssets Start
export const PersonalAssetsHeader = {
    investment_name: 'Item',
    property_type: 'Property Type',
    manufacturer: 'Manufacturer',
    model: 'Model',
    location: 'Location',
    amount_invested: 'Amount Invested($)',
    current_value: 'Market Value($)',
    insured_value: 'Insured Value($)',
    insured_date: 'Insured Date',
    insurance_carrier: 'Insurance Carrier',
    insurance_policy_number: 'Insurance Policy Number',
    // is_active: true,
    // fixed_income: false,
    // investment_category: process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID,
    note: 'Notes',
    // is_archived: '',
    amount_borrowed: 'Amount Borrowed($)',
    contingent_liability: 'Contingent Liability(%)',
    'investment_headers_created_by.full_name': "Created By",
    'investment_headers_modified_by.full_name': "Last Modified By",
}
//PersonalAssets End


//OperatingBusiness Start
export const OperatingBusinessHeader = {
    business_name: 'Legal Entity Name',
    primary_product_service: 'Primary Product/Service',
    managing_partner: 'Managing Partner',
    percentage_interest: '',
    value: 'Value($)',
    status: 'Status',
    exit_strategy: 'Goals/Exit Strategy',
    home_address: 'Home Address',
    key_emp_name: 'Key Employees Name',
    key_emp_title: 'Key Employees Title',
    total_liabilities: 'Total Liabilities($)',
    total_assets: 'Total Assets($)',
    number_of_employees: 'Number of Employees',
    estimated_value: 'Estimated value($)',
    number_of_employees_entry: 'Number of Employees Entry',
    value_entry: 'Value($)',
    url: 'URL',
    state_country_of_incorporation: 'State/Country of Incorporation',
    ein: 'Notes',
    contingent_liability: 'Contingent Liability(%)',
    ownership_percentage: 'Ownership Percentage(%)',
    'investment_headers_created_by.full_name': "Created By",
    'investment_headers_modified_by.full_name': "Last Modified By",
}
//OperatingBusiness End


//EstatePlanning Start
export const EstatePlanningHeader = {
     document_name: 'Document Name', 
     description: 'Description',
     document_type: 'Document Type', 
     extension: 'Extension', 
     other: 'Other', 
     created_by: 'Created By', 
     updated_by: 'Last Modified By' 
}
//EstatePlanning End


export const DocumentHeader = {
    document_name: 'Document Name',
    document_type: 'Documnet Type',
    document_uploaded_by: 'Document Uploaded By',
    extension: 'Extension', 
    other: 'Other', 
    updated_at: 'Updated On',
    createdAt: 'Created On',
    uploaded_date:'Uploaded Date',
    document_name: 'Document Name', 
}