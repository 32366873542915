import { get } from '.';
import { GET_INVESTMENT_BY_ID, GET_STOCK_INV_INTEREST, GET_DOCUMENT_LIST_BY_INV_ID, GET_REAL_ESTATE_HOLDINGS_BY_ID, GET_ALL_INCOME_EXPENSE, GET_ALL_TENANT, GET_REAL_ESTATE_INVESTMENT_BY_ID, GET_MINERAL_ASSET, GET_PERSONAL_ASSETS_BY_ID, GET_OPERATING_BUSINESS, DOCUMENT_GET_BY_OB_YEAR_ID, ESTATE_PLANNING_DOCUMENT_GET_BY_ID, DOCUMENT_GET_BY_INVESTMENT_ID } from './endpoints';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { concatUrlWithId, formatDateAndTime, mapDataWithHeaders } from '../utils/commonUtils';
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import { LiquidAssestHeader, InvestmentEarnedHeader, ProfessionalContactHeader, IncomeExpenseHeader, TenetHeader, RealEstateHoldingsHeader, PrivateEquityHeader, OperatingBusinessHeader, PersonalAssetsHeader, MineralAssertHeader, EstatePlanningHeader, DocumentHeader } from '../utils/exportConfig';
import moment from 'moment';

const notify = (message, type) => toast(message, { type });

function DocumentDetailsFormat(documentDetails) {
    // console.log("document ::",documentDetails);

    return documentDetails.map((doc) => ({
        document_name: doc?.document_name || "N/A",
        document_type: doc?.document_type || "N/A",
        document_uploaded_by: doc?.document_uploaded_by?.full_name || "N/A",
        extension: doc?.extension || "N/A",
        other: doc?.other || "",
        updated_at: formatDateAndTime(doc?.updatedAt, "MM/DD/YYYY hh:mm a"),
        createdAt: formatDateAndTime(doc?.createdAt, "MM/DD/YYYY hh:mm a"),
        uploaded_date: formatDateAndTime(doc?.uploaded_date, "MM/DD/YYYY hh:mm a"),
    }));
}

export const exportLiquidAssest = async (type, dataOrId) => {
    try {
        let invData, invId, contacts;
        if (type === 'id') {
            invData = await get(concatUrlWithId(GET_INVESTMENT_BY_ID, dataOrId)); // This will investment by id;    
        } else {
            invData = dataOrId;
        }
        invId = invData.id;
        contacts = invData?.professional_contacts || [];

        invData['first_activity_date'] = formatDateAndTime(invData['stock_investment_details.first_activity_date'], "YYYY-MM-DD");
        invData['maturity_date'] = invData['stock_investment_details.maturity_date'] ? moment(invData['stock_investment_details.maturity_date']).format('YYYY-MM-DD') : '';

        // Generate Excel sheet 
        const workbook = XLSX.utils.book_new();
        const intEarnList = await get(concatUrlWithId(GET_STOCK_INV_INTEREST, invData?.['stock_investment_details.id']));
        const documentDetails = await get(DOCUMENT_GET_BY_INVESTMENT_ID, { investment_id: invId });
        const FormattedDetails = await DocumentDetailsFormat(documentDetails.data);
        let DocumentData = mapDataWithHeaders(FormattedDetails, DocumentHeader);
        let investmentData = mapDataWithHeaders([invData], LiquidAssestHeader)
        let professionalCont = mapDataWithHeaders(contacts, ProfessionalContactHeader)
        let InterestEarnedArr = mapDataWithHeaders(intEarnList, InvestmentEarnedHeader);

        const sheet1 = XLSX.utils.json_to_sheet(investmentData);
        XLSX.utils.book_append_sheet(workbook, sheet1, "Liquid Assest");

        if (professionalCont?.length) {
            const sheet2 = XLSX.utils.json_to_sheet(professionalCont);
            XLSX.utils.book_append_sheet(workbook, sheet2, "Professional Contact");
        }

        if (InterestEarnedArr?.length) {
            const sheet3 = XLSX.utils.json_to_sheet(InterestEarnedArr);
            XLSX.utils.book_append_sheet(workbook, sheet3, "Investment Earned");
        }
        if (DocumentData?.length) {
            const sheet3 = XLSX.utils.json_to_sheet(DocumentData);
            XLSX.utils.book_append_sheet(workbook, sheet3, "Document Details");
        }

        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const documentList = await get(concatUrlWithId(GET_DOCUMENT_LIST_BY_INV_ID, invId));
        const investmentName = invData.investment_name || 'Liquid Assest';
        const documentFilesArr = documentList.document;
        const zip = new JSZip();
        zip.file(investmentName + '.xlsx', excelFile);
        if (documentFilesArr?.length) {
            const folderNmae = 'Documents';
            const imageFolder = zip.folder(folderNmae);
            for (const files of documentFilesArr) {
                console.log('Processing file:', files);
                try {
                    // Generate the file name
                    const fileName =
                        files.document_name +
                        '_' +
                        files.document_location_url.split('_').pop();

                    console.log('Generated file name:', fileName);

                    const fileResponse = await fetch(files.document_location_url);

                    if (!fileResponse.ok) {
                        console.error(
                            'Error fetching the file:',
                            files.document_location_url
                        );
                        continue;
                    }

                    const fileData = await fileResponse.blob();
                    imageFolder.file(fileName, fileData);
                } catch (error) {
                    console.error('Error processing file:', error);
                }
            }

        }
        // Generate the ZIP file
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Create a download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        link.download = investmentName + '.zip'; // Name of the ZIP file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true
    } catch (error) {
        console.log('Error in the exportLiquidAssest api function:: ', error);
        return false;
    }
}
export const exportRealEstateHolding = async (type, dataOrId) => {
    try {
        let invData, invId, contacts;
        if (type === 'id') {
            invData = await get(concatUrlWithId(GET_REAL_ESTATE_HOLDINGS_BY_ID, dataOrId));
        } else {
            invData = dataOrId;
        }
        invId = invData.id;
        contacts = invData?.professional_contacts || [];

        invData['createdAt'] = formatDateAndTime(invData['createdAt'], "MM/DD/YYYY hh:mm a");
        invData['updatedAt'] = formatDateAndTime(invData['updatedAt'], "MM/DD/YYYY hh:mm a");

        // Generate Excel sheet 
        const workbook = XLSX.utils.book_new();
        // const intEarnList = await get(concatUrlWithId(GET_STOCK_INV_INTEREST, invData?.['stock_investment_details.id']));
        const incomeList = await get(concatUrlWithId(GET_ALL_INCOME_EXPENSE, `${invId}/INCOME`));
        const expenseList = await get(concatUrlWithId(GET_ALL_INCOME_EXPENSE, `${invId}/EXPENSE`));
        const tenetList = await get(concatUrlWithId(GET_ALL_TENANT, `${invId}/TENANT`));
        const documentDetails = await get(DOCUMENT_GET_BY_INVESTMENT_ID, { investment_id: invId });
        const FormattedDetails = await DocumentDetailsFormat(documentDetails.data);
        let DocumentData = mapDataWithHeaders(FormattedDetails, DocumentHeader);
        let investmentData = mapDataWithHeaders([invData], RealEstateHoldingsHeader)
        let professionalCont = mapDataWithHeaders(contacts, ProfessionalContactHeader);
        let incomeData = mapDataWithHeaders(incomeList, IncomeExpenseHeader)
        let expenseData = mapDataWithHeaders(expenseList, IncomeExpenseHeader)
        let tenetData = mapDataWithHeaders(tenetList, TenetHeader);
        // let InterestEarnedArr = mapDataWithHeaders(intEarnList, InvestmentEarnedHeader);
        const sheet1 = XLSX.utils.json_to_sheet(investmentData);
        XLSX.utils.book_append_sheet(workbook, sheet1, "Real Estate Holdings");

        if (professionalCont?.length) {
            const sheet2 = XLSX.utils.json_to_sheet(professionalCont);
            XLSX.utils.book_append_sheet(workbook, sheet2, "Professional Contact");
        }
        if (incomeData?.length) {
            const sheet2 = XLSX.utils.json_to_sheet(incomeData);
            XLSX.utils.book_append_sheet(workbook, sheet2, "Income");
        }
        if (expenseData?.length) {
            const sheet3 = XLSX.utils.json_to_sheet(expenseData);
            XLSX.utils.book_append_sheet(workbook, sheet3, "Expense");
        }

        if (tenetData?.length) {
            const sheet4 = XLSX.utils.json_to_sheet(tenetData);
            XLSX.utils.book_append_sheet(workbook, sheet4, "Tenant");
        }
        if (DocumentData?.length) {
            const sheet3 = XLSX.utils.json_to_sheet(DocumentData);
            XLSX.utils.book_append_sheet(workbook, sheet3, "Document Details");
        }
        // if (InterestEarnedArr?.length) {
        //     const sheet3 = XLSX.utils.json_to_sheet(InterestEarnedArr);
        //     XLSX.utils.book_append_sheet(workbook, sheet3, "Investment Earned");
        // }

        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const documentList = await get(concatUrlWithId(GET_DOCUMENT_LIST_BY_INV_ID, invId));
        const investmentName = invData.investment_name || 'Real Estate Holdings';
        const documentFilesArr = documentList.document;
        const zip = new JSZip();
        zip.file(investmentName + '.xlsx', excelFile);
        let property_image = invData['real_estate_holding_details.property_image'] || null;
        if (property_image) {
            const fileName = investmentName + '.' + property_image.split('.').pop();
            const fileResponse = await fetch(property_image);
            if (fileResponse.ok) {
                const fileData = await fileResponse.blob()
                zip.file(fileName, fileData);
            }else{
                console.error('Error in Property image Fetch ',fileResponse);
            }
        }
        if (documentFilesArr?.length) {
            const folderNmae = 'Documents';
            const imageFolder = zip.folder(folderNmae);
            for (const files of documentFilesArr) {
                try {
                    // Generate the file name
                    const fileName =
                        files.document_name +
                        '_' +
                        files.document_location_url.split('_').pop();


                    const fileResponse = await fetch(files.document_location_url);

                    if (!fileResponse.ok) {
                        console.error(
                            'Error fetching the file:',
                            files.document_location_url
                        );
                        continue;
                    }

                    const fileData = await fileResponse.blob();
                    imageFolder.file(fileName, fileData);
                } catch (error) {
                    console.error('Error processing file:', error);
                }
            }

        }
        // Generate the ZIP file
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Create a download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        link.download = investmentName + '.zip'; // Name of the ZIP file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true
    } catch (error) {
        console.log('Error in the exportLiquidAssest api function:: ', error);
        return false;
    }
}
export const exportPrivateEquity = async (type, dataOrId) => {
    try {
        let invData, invId, contacts;
        if (type === 'id') {
            invData = await get(concatUrlWithId(GET_REAL_ESTATE_INVESTMENT_BY_ID, dataOrId));
        } else {
            invData = dataOrId;
        }
        invId = invData.id;
        contacts = invData?.professional_contacts || [];

        invData['createdAt'] = formatDateAndTime(invData['createdAt'], "MM/DD/YYYY hh:mm a");
        invData['updatedAt'] = formatDateAndTime(invData['updatedAt'], "MM/DD/YYYY hh:mm a");

        // Generate Excel sheet 
        const workbook = XLSX.utils.book_new();

        let investmentData = mapDataWithHeaders([invData], PrivateEquityHeader)
        let professionalCont = mapDataWithHeaders(contacts, ProfessionalContactHeader);
        const documentDetails = await get(DOCUMENT_GET_BY_INVESTMENT_ID, { investment_id: invId });
        const FormattedDetails = await DocumentDetailsFormat(documentDetails.data);
        let DocumentData = mapDataWithHeaders(FormattedDetails, DocumentHeader);
        // let InterestEarnedArr = mapDataWithHeaders(intEarnList, InvestmentEarnedHeader);
        const sheet1 = XLSX.utils.json_to_sheet(investmentData);
        XLSX.utils.book_append_sheet(workbook, sheet1, "Private Equity");

        if (professionalCont?.length) {
            const sheet2 = XLSX.utils.json_to_sheet(professionalCont);
            XLSX.utils.book_append_sheet(workbook, sheet2, "Professional Contact");
        }
        // if (InterestEarnedArr?.length) {
        //     const sheet3 = XLSX.utils.json_to_sheet(InterestEarnedArr);
        //     XLSX.utils.book_append_sheet(workbook, sheet3, "Investment Earned");
        // }
        if (DocumentData?.length) {
            const sheet3 = XLSX.utils.json_to_sheet(DocumentData);
            XLSX.utils.book_append_sheet(workbook, sheet3, "Document Details");
        }

        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const documentList = await get(concatUrlWithId(GET_DOCUMENT_LIST_BY_INV_ID, invId));
        const investmentName = invData.investment_name || 'Private Equity';
        const documentFilesArr = documentList.document;
        const zip = new JSZip();
        zip.file(investmentName + '.xlsx', excelFile);

        let property_image = invData['real_estate_investment_details.property_image'] || null;
        if (property_image) {
            const fileName = investmentName + '.' + property_image.split('.').pop();
            const fileResponse = await fetch(property_image);
            if (fileResponse.ok) {
                const fileData = await fileResponse.blob()
                zip.file(fileName, fileData);
            }else{
                console.error('Error in Property image Fetch ',fileResponse);
            }
        }

        if (documentFilesArr?.length) {
            const folderNmae = 'Documents';
            const imageFolder = zip.folder(folderNmae);
            for (const files of documentFilesArr) {
                console.log('Processing file:', files);
                try {
                    // Generate the file name
                    const fileName =
                        files.document_name +
                        '_' +
                        files.document_location_url.split('_').pop();

                    console.log('Generated file name:', fileName);

                    const fileResponse = await fetch(files.document_location_url);

                    if (!fileResponse.ok) {
                        console.error(
                            'Error fetching the file:',
                            files.document_location_url
                        );
                        continue;
                    }

                    const fileData = await fileResponse.blob();
                    imageFolder.file(fileName, fileData);
                } catch (error) {
                    console.error('Error processing file:', error);
                }
            }

        }
        // Generate the ZIP file
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Create a download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        link.download = investmentName + '.zip'; // Name of the ZIP file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true
    } catch (error) {
        console.log('Error in the exportLiquidAssest api function:: ', error);
        return false;
    }
}
export const exportRoyaltyIncome = async (type, dataOrId) => {
    try {
        let invData, invId, contacts;
        if (type === 'id') {
            invData = await get(concatUrlWithId(GET_MINERAL_ASSET, dataOrId));
        } else {
            invData = dataOrId;
        }
        invId = invData.id;
        contacts = invData?.professional_contacts || [];

        invData['createdAt'] = formatDateAndTime(invData['createdAt'], "MM/DD/YYYY hh:mm a");
        invData['updatedAt'] = formatDateAndTime(invData['updatedAt'], "MM/DD/YYYY hh:mm a");

        // Generate Excel sheet 
        const workbook = XLSX.utils.book_new();

        let investmentData = mapDataWithHeaders([invData], MineralAssertHeader)
        let professionalCont = mapDataWithHeaders(contacts, ProfessionalContactHeader);
        const documentDetails = await get(DOCUMENT_GET_BY_INVESTMENT_ID, { investment_id: invId });
        const FormattedDetails = await DocumentDetailsFormat(documentDetails.data);
        let DocumentData = mapDataWithHeaders(FormattedDetails, DocumentHeader);
        // let InterestEarnedArr = mapDataWithHeaders(intEarnList, InvestmentEarnedHeader);
        const sheet1 = XLSX.utils.json_to_sheet(investmentData);
        XLSX.utils.book_append_sheet(workbook, sheet1, "Royalty Income");

        if (professionalCont?.length) {
            const sheet2 = XLSX.utils.json_to_sheet(professionalCont);
            XLSX.utils.book_append_sheet(workbook, sheet2, "Professional Contact");
        }
        // if (InterestEarnedArr?.length) {
        //     const sheet3 = XLSX.utils.json_to_sheet(InterestEarnedArr);
        //     XLSX.utils.book_append_sheet(workbook, sheet3, "Investment Earned");
        // }
        if (DocumentData?.length) {
            const sheet3 = XLSX.utils.json_to_sheet(DocumentData);
            XLSX.utils.book_append_sheet(workbook, sheet3, "Document Details");
        }
        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const documentList = await get(concatUrlWithId(GET_DOCUMENT_LIST_BY_INV_ID, invId));
        const investmentName = invData.investment_name || 'Royalty Income';
        const documentFilesArr = documentList.document;
        const zip = new JSZip();
        zip.file(investmentName + '.xlsx', excelFile);
        if (documentFilesArr?.length) {
            const folderNmae = 'Documents';
            const imageFolder = zip.folder(folderNmae);
            for (const files of documentFilesArr) {
                console.log('Processing file:', files);
                try {
                    // Generate the file name
                    const fileName =
                        files.document_name +
                        '_' +
                        files.document_location_url.split('_').pop();

                    console.log('Generated file name:', fileName);

                    const fileResponse = await fetch(files.document_location_url);

                    if (!fileResponse.ok) {
                        console.error(
                            'Error fetching the file:',
                            files.document_location_url
                        );
                        continue;
                    }

                    const fileData = await fileResponse.blob();
                    imageFolder.file(fileName, fileData);
                } catch (error) {
                    console.error('Error processing file:', error);
                }
            }

        }
        // Generate the ZIP file
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Create a download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        link.download = investmentName + '.zip'; // Name of the ZIP file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true
    } catch (error) {
        console.log('Error in the exportLiquidAssest api function:: ', error);
        return false;
    }
}
export const exportPersonalAssets = async (type, dataOrId) => {
    try {
        let invData, invId, contacts;
        if (type === 'id') {
            invData = await get(concatUrlWithId(GET_PERSONAL_ASSETS_BY_ID, dataOrId));
        } else {
            invData = dataOrId;
        }
        invId = invData.id;
        contacts = invData?.professional_contacts || [];

        invData['createdAt'] = formatDateAndTime(invData['createdAt'], "MM/DD/YYYY hh:mm a");
        invData['updatedAt'] = formatDateAndTime(invData['updatedAt'], "MM/DD/YYYY hh:mm a");

        // Generate Excel sheet 
        const workbook = XLSX.utils.book_new();

        let investmentData = mapDataWithHeaders([invData], PersonalAssetsHeader)
        let professionalCont = mapDataWithHeaders(contacts, ProfessionalContactHeader);
        // let InterestEarnedArr = mapDataWithHeaders(intEarnList, InvestmentEarnedHeader);
        const documentDetails = await get(DOCUMENT_GET_BY_INVESTMENT_ID, { investment_id: invId });
        const FormattedDetails = await DocumentDetailsFormat(documentDetails.data);
        let DocumentData = mapDataWithHeaders(FormattedDetails, DocumentHeader);
        const sheet1 = XLSX.utils.json_to_sheet(investmentData);
        XLSX.utils.book_append_sheet(workbook, sheet1, "Personal Assets");

        if (professionalCont?.length) {
            const sheet2 = XLSX.utils.json_to_sheet(professionalCont);
            XLSX.utils.book_append_sheet(workbook, sheet2, "Professional Contact");
        }
        if (DocumentData?.length) {
            const sheet3 = XLSX.utils.json_to_sheet(DocumentData);
            XLSX.utils.book_append_sheet(workbook, sheet3, "Document Details");
        }
        // if (InterestEarnedArr?.length) {
        //     const sheet3 = XLSX.utils.json_to_sheet(InterestEarnedArr);
        //     XLSX.utils.book_append_sheet(workbook, sheet3, "Investment Earned");
        // }

        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const documentList = await get(concatUrlWithId(GET_DOCUMENT_LIST_BY_INV_ID, invId));

        const investmentName = invData.investment_name || 'Personal Assets';
        const documentFilesArr = documentList.document;
        const zip = new JSZip();
        zip.file(investmentName + '.xlsx', excelFile);
        if (documentFilesArr?.length) {
            const folderNmae = 'Documents';
            const imageFolder = zip.folder(folderNmae);
            for (const files of documentFilesArr) {
                try {
                    // Generate the file name
                    const fileName =
                        files.document_name +
                        '_' +
                        files.document_location_url.split('_').pop();

                    console.log('Generated file name:', fileName);

                    const fileResponse = await fetch(files.document_location_url);

                    if (!fileResponse.ok) {
                        console.error(
                            'Error fetching the file:',
                            files.document_location_url
                        );
                        continue;
                    }

                    const fileData = await fileResponse.blob();
                    imageFolder.file(fileName, fileData);
                } catch (error) {
                    console.error('Error processing file:', error);
                }
            }

        }
        // Generate the ZIP file
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Create a download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        link.download = investmentName + '.zip'; // Name of the ZIP file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true
    } catch (error) {
        console.log('Error in the exportLiquidAssest api function:: ', error);
        return false;
    }
}
export const exportOperatingBusiness = async (type, dataOrId) => {
    try {
        let invData, invId, contacts;
        if (type === 'id') {
            invData = await get(concatUrlWithId(GET_OPERATING_BUSINESS, dataOrId));
        } else {
            invData = dataOrId;
        }

        invId = invData.id;
        contacts = invData?.professional_contacts || [];

        invData['createdAt'] = formatDateAndTime(invData['createdAt'], "MM/DD/YYYY hh:mm a");
        invData['updatedAt'] = formatDateAndTime(invData['updatedAt'], "MM/DD/YYYY hh:mm a");

        // Generate Excel sheet 
        const workbook = XLSX.utils.book_new();

        let investmentData = mapDataWithHeaders([invData], OperatingBusinessHeader)
        let professionalCont = mapDataWithHeaders(contacts, ProfessionalContactHeader);
        const documentDetails = await get(DOCUMENT_GET_BY_OB_YEAR_ID, { "yearid": invId });
        const FormattedDetails = await DocumentDetailsFormat(documentDetails.data);
        let DocumentData = mapDataWithHeaders(FormattedDetails, DocumentHeader);
        const sheet1 = XLSX.utils.json_to_sheet(investmentData);
        XLSX.utils.book_append_sheet(workbook, sheet1, "Operating Business");

        if (professionalCont?.length) {
            const sheet2 = XLSX.utils.json_to_sheet(professionalCont);
            XLSX.utils.book_append_sheet(workbook, sheet2, "Professional Contact");
        }
        if (DocumentData?.length) {
            const sheet3 = XLSX.utils.json_to_sheet(DocumentData);
            XLSX.utils.book_append_sheet(workbook, sheet3, "Document Details");
        }
        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const documentList = await get(DOCUMENT_GET_BY_OB_YEAR_ID, { "yearid": invId });

        const investmentName = invData.business_name || 'Operating Business';
        const documentFilesArr = documentList.data;
        const zip = new JSZip();
        zip.file(investmentName + '.xlsx', excelFile);
        if (documentFilesArr?.length) {
            const folderNmae = 'Documents';
            const imageFolder = zip.folder(folderNmae);
            for (const files of documentFilesArr) {
                try {
                    // Generate the file name
                    const fileName =
                        files.document_name +
                        '_' +
                        files.document_location_url.split('_').pop();
                    const fileResponse = await fetch(files.document_location_url);

                    if (!fileResponse.ok) {
                        console.error(
                            'Error fetching the file:',
                            files.document_location_url
                        );
                        continue;
                    }

                    const fileData = await fileResponse.blob();
                    imageFolder.file(fileName, fileData);
                } catch (error) {
                    console.error('Error processing file:', error);
                }
            }

        }
        // Generate the ZIP file
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Create a download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        link.download = investmentName + '.zip'; // Name of the ZIP file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true
    } catch (error) {
        console.log('Error in the exportLiquidAssest api function:: ', error);
        return false;
    }
}
export const exportEstatePlanning = async (type, dataOrId) => {
    try {
        console.log("ID:", dataOrId);

        let invData, formattedData;
        if (type === 'id') {
            invData = await get(ESTATE_PLANNING_DOCUMENT_GET_BY_ID, { id: dataOrId });
        } else {
            invData = dataOrId;
        }

        const { data } = invData;

        formattedData = {
            document_type: data?.get_type?.name,
            description: data?.description,
            document_url: data?.document_url,
            extension: data?.extension,
            other: data?.other || "",
            created_by: data?.estate_planning_created_by?.full_name,
            updated_at: formatDateAndTime(data?.updatedAt, "MM/DD/YYYY hh:mm a"),
            updated_by: data?.estate_planning_modified_by?.full_name || "N/A",
        };


        const workbook = XLSX.utils.book_new();
        const investmentData = mapDataWithHeaders([formattedData], EstatePlanningHeader);
        const sheet1 = XLSX.utils.json_to_sheet(investmentData);
        XLSX.utils.book_append_sheet(workbook, sheet1, "Estate Planning");

        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const zip = new JSZip();
        const investmentName = formattedData.document_type || 'Estate Planning';
        zip.file(`${investmentName}.xlsx`, excelFile);
        if (data?.document_url) {

            const folderName = 'Documents';
            const imageFolder = zip.folder(folderName);

            try {
                const fileName = `${formattedData.document_type}_${data.document_url.split('/').pop()}`;

                const fileResponse = await fetch(data.document_url);

                if (!fileResponse.ok) {
                    console.error('Error fetching the file:', data.document_url);
                } else {
                    const fileData = await fileResponse.blob();
                    imageFolder.file(fileName, fileData);
                }
            } catch (error) {
                console.error('Error processing single file:', error);
            }
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        link.download = `${investmentName}.zip`; // Name of the ZIP file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return true;
    } catch (error) {
        console.error('Error in the exportEstatePlanning function:', error);
        return false;
    }
};


