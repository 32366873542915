import React, { useState, useEffect, useContext } from 'react';
import Datatable from '../../components/Table/Datatable';
import { get, post, put, remove } from '../../api/index';
import { GET_ALL_OPERATING_BUSINESS, GET_REMINDER_LISTS, DELETE_REMINDER_BY_ID } from '../../api/endpoints'
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css'
import { addValueLabel, checkPermission, concatUrlWithId, notify, refreshCount } from '../../utils/commonUtils';
import { PageLoader } from '../../Context/PageLoader';
import { useNavigate } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from '../../components/Footer';
import { UserPermissionStore } from '../../Store/UserPermission';
import { dateOptions, reminderOptionType, timeOptions, weeklyOptions } from '../../Data/ReminderOptions';
import moment from "moment";
import ReminderModelForm from '../../components/Reminder/ReminderModelForm';

function ReminderList() {

    const { loading, setLoading } = useContext(PageLoader);
    const [initialValues, setInitialValues] = useState({ business_name: '', primary_product_service: '', operating_business_id: '' });
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [enablePrint, SetEnablePrint] = useState(false);
    const [userPermissions, setUserPermissions] = useState("");
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [reminderList, setReminderList] = useState([]);
    const [originalReminderList, setOriginalReminderList] = useState([]);
    const [reminderStatus, setReminderStatus] = useState(false);
    const [modelType, setModelType] = useState('add')
    const [fieldValue, setFieldValue] = useState({});
    const [selectedFilter, setSelectedFilter] = useState({ label: 'All', value: 'All' });
    const [filterOptions, setFilterOptions] = useState("");

    const history = useNavigate();


    const fetchData = async () => {
        try {
            const getReminderData = await post((GET_REMINDER_LISTS));
            const InvestmentLabel = {
                "operating_business": 'Operating Business',
                "estate_planning": 'Estate Planning'
            }
            if (getReminderData && getReminderData?.reminderList?.length) {
                const tempRemindervalue = getReminderData?.reminderList.map((rem) => {
                    if (!rem.hasOwnProperty('investment_value') || rem['investment_value'] === null) {
                        rem['investment_value'] = InvestmentLabel[rem['entity_type']] || ""; // Fallback to an empty string if no matching value
                    }
                    rem['email_label'] = rem['email']?.length ? rem['email'].join(', ') : "";
                    rem['occurrence_type_label'] = addValueLabel(rem['occurrence_type'], reminderOptionType);
                    rem['occurrence_on_label'] = rem['occurrence_type'] === 'onetime' ? moment(rem['occurrence_on']).format("MM/DD/YYYY") :
                        rem['occurrence_type'] === 'yearly' ? moment(rem['occurrence_on']).format("MMM-DD") :
                            rem['occurrence_type'] === 'daily' ? 'Every Day' :
                                addValueLabel(rem['occurrence_on'], rem['occurrence_type'] === "weekly" ? weeklyOptions : dateOptions);
                    rem['occurrence_time_label'] = addValueLabel(rem['occurrence_time'], timeOptions);
                    rem['investment_value'] = rem['investment_value'] === null ? InvestmentLabel[rem['entity_type']] : rem['investment_value']
                    return rem;
                });

                // Save the original and filtered list
                setOriginalReminderList(tempRemindervalue);
                setReminderList(tempRemindervalue);
                const dropdownOptionsMap = new Map();
                dropdownOptionsMap.set('all', { label: 'All', value: 'all' }); // Add the 'All' option by default
                tempRemindervalue.forEach(item => {
                    const entityType = item.entity_type;
                    const value = item.investment_value;

                    if (entityType === 'investment' && value !== null) {
                        dropdownOptionsMap.set(value, { label: value, value });
                    } else if (entityType === 'operating_business') {
                        dropdownOptionsMap.set('operating_business', { label: 'Operating Business', value: 'operating_business' });
                    } else if (entityType === 'estate_planning') {
                        dropdownOptionsMap.set('estate_planning', { label: 'Estate Planning', value: 'estate_planning' });
                    } else if (value) {
                        dropdownOptionsMap.set(value, { label: value, value });
                    }
                });

                // Convert Map values to an array for the dropdown options
                const dropdownOptions = Array.from(dropdownOptionsMap.values());
                setFilterOptions(dropdownOptions);
            } else {
                setReminderList([]);
                setOriginalReminderList([]);
            }
            setReminderStatus(getReminderData?.reminderStatus || false);
            setLoading(false);
        } catch (error) {
            console.log('Error in the fetch Data value ::: ', error);
            setLoading(false);
        }
    };


    const handleOnClickAction = (type, id, name, data) => {
        switch (type) {
            case 'edit':
                handleEditClick(id, data);
                break;
            case 'delete':
                handleDeleteClick(id, name);
                break;
            default:
                break;
        }
    };

    const handleEditClick = (reminder_id, reminder_data) => {
        setFieldValue(reminder_data);
        setModelType('edit')
        setShowReminderModel(true);
    };


    const handleDeleteClick = (reminder_id, reminder_name) => {
        confirmAlert({
            title: 'Delete Reminder',
            message: `Are you sure you want to delete the ${reminder_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(reminder_id, reminder_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const handleDelete = async (reminder_id, reminder_name) => {
        try {
            setLoading(true)
            let deleteReminder = await remove(concatUrlWithId(DELETE_REMINDER_BY_ID, reminder_id));

            if (deleteReminder) {
                // fetchInvestmentList()
            }
            notify(`${reminder_name} successfully deleted`, 'success')
            await fetchData();
            const button = document.getElementById('notification-btn-fetch');
            if (button) {
                button.click();
            }
            setLoading(false)
        } catch (error) {
            notify(`Unable to delete ${reminder_name}`, 'warning')
            console.log('Error while deleteing the error : ', error)
            setLoading(false);
        }
    };

    const handleReminderModelClose = () => {
        setFieldValue({});
        setModelType('add');
        setShowReminderModel(false);
    };

    const handleFilterChange = async (selectedValue, selectedLabel) => {
        setLoading(true);
        console.log("Selected values ::: ", selectedValue);
        console.log("selectedLabel ::: ", selectedLabel);
        console.log("originalReminderList ::: ", originalReminderList);

        setSelectedFilter({ label: selectedLabel, value: selectedValue });

        if (selectedValue == 'all') {
            setReminderList(originalReminderList);
        } else {
            let filterEntityType = selectedValue == 'estate_planning' ? 'estate_planning' : selectedValue == 'operating_business' ? 'operating_business' : 'investment';

            const filteredList = originalReminderList.filter(item => {
                if (filterEntityType == 'investment') {
                    return item.entity_type == filterEntityType && item.investment_value == selectedValue;
                } else {
                    return item.entity_type == filterEntityType;
                }
            });
            setReminderList(filteredList);
        }
        // else {
        //     console.log("TEst ::: ",originalReminderList);
        //     let filterEntityType = selectedValue == 'estate_planning' ? 'estate_planning' : selectedValue == 'operating_business' ? 'operating_business' :'investment';

        //     const filteredList = originalReminderList.filter(item => {
        //         if (selectedValue) {
        //             return item.investment_value == selectedValue;
        //         }
        //     });
        //     setReminderList(filteredList);
        // }

        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        authCheckAndFetch();
    }, [])

    const authCheckAndFetch = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);
        fetchData();
    };

    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: 'reminder_name', text: 'Schedule Name', sort: true, isViewField: false, handleOnClick: handleOnClickAction, onClickParams: ['id'],
            headerWidth: '200px', wordWarp: true, showDotsOnLengthExceed: true, hMinWidth: '140px'
        },
        { dataField: 'investment_value', text: 'Asset Type', sort: true },
        { dataField: 'occurrence_type_label', text: 'Occurrence', sort: true },
        { dataField: 'occurrence_on_label', text: 'Day', headerWidth: '80px', sort: true },
        { dataField: 'occurrence_time_label', text: 'Time', headerWidth: '80px', sort: true },
        { dataField: 'email_label', text: 'Email', isEmailField: true, headerWidth: '200px', hMinWidth: '170px', hMaxWidth: '200px' },
    ];

    let tempAction = {
        dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, hideArchive: true,
        tooltipSuffixText: ' ', handleOnClick: handleOnClickAction, onClickParams: ['id', 'reminder_name'], reminderCount: ['active_reminders_count'],
        disableEdit: true, disableDelete: true, setReminder: false
    }

    columns.push(tempAction);

    const handlePrintClick = () => {
        confirmAlert({
            title: 'Alert',
            message: `For better viewing, please use landscape mode.`,
            buttons: [
                {
                    label: 'Done',
                    onClick: () => { SetEnablePrint(true); setLoading(true); }
                },
            ]
        });
    }

    return (
        <>

            <div className='row'>
                <div className='col-6'>
                    <legend className='text-bold'>Reminder List
                        {/* <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                <i className="fa fa-print btn btn-circle print-btn" onClick={() => { handlePrintClick() }} ></i>
                                            </OverlayTrigger> */}
                    </legend>
                </div>
                <div className='col-6'>
                </div>
            </div>
            <Datatable data={reminderList} columns={columns} csvFileName={'Reminder List'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} isReminderList={true} permissionsArr={[]} actionPermissionPrefix filterOptions={filterOptions} handleFilterChange={handleFilterChange} selectedFilter={selectedFilter} />

            {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
            <ReminderModelForm show={showReminderModel} mode={modelType} fieldValue={fieldValue} handleClose={handleReminderModelClose} onSuccess={fetchData} headerName={'Reminder'} />
        </>

    );
}

export default ReminderList;