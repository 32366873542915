import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Footer from "../components/Footer";
import { PageLoader } from '../Context/PageLoader';
import { useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Datatable from '../components/Table/Datatable';
import DataChart from '../components/DataChart';
import { get } from '../api';
import { GET_OVERVIEW_DATA } from '../api/endpoints'
import { checkPermission } from '../utils/commonUtils';
import { UserPermissionStore } from '../Store/UserPermission';
import PieChart from '../components/PieChart';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { all } from 'axios';

function Overview() {
  const [overviewDataList, setOverViewList] = useState([]);
  const [selectedInvList, setSelectedInvList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({ label: 'All', value: 'all' });
  const [projectedIncomeData, setProjectedIncomeData] = useState([]);
  const [filterOptions, setFilterOptions] = useState(null);
  const { loading, setLoading } = useContext(PageLoader);
  const [userPermissions, setUserPermissions] = useState([]);
  const navigation = useNavigate();
  const notify = (message, type) => toast(message, { type });
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const [enablePrint, SetEnablePrint] = useState(false)
  const [chartData, setChartData] = useState([]);
  const locationsearch = window.location.search;
  const [chartClick, setChartClick] = useState(false)
  const [isBackNavigation, setIsBackNavigation] = useState(false);

  const fetchInvestmentList = async () => {
    try {
      setLoading(true);
      const overviewData = await get(GET_OVERVIEW_DATA);

      if (overviewData?.investmentData?.length) {
        let overviewInvData = overviewData.investmentData;

        const filterData = overviewInvData.filter(value => value.current_value != null);

        const overviewDataList = filterData.map(item => {
          if (item['investment_categories.value'] == "Royalty Income") {
            return {
              ...item,
              "investment_categories.value": "Royalty Interest"
            };
          }
          return item;
        });

        // setOverViewList(overviewInvData);
        // setSelectedInvList(overviewInvData);

        setOverViewList(overviewDataList);
        setSelectedInvList(overviewDataList);

        const dropdownOptionsSet = new Set();
        overviewDataList.forEach(item => {
          const value = item['investment_categories.value'];
          if (value !== null) {
            dropdownOptionsSet.add(value);
          }
        });

        const dropdownOptions = Array.from(dropdownOptionsSet).reduce((options, value) => {
          options.push({ label: value, value });
          return options;
        }, [{ label: 'All', value: 'all' }]);
        setFilterOptions(dropdownOptions);
        processQueryParams(overviewDataList, dropdownOptions)

      };

      if (overviewData?.projectIncome && Object.keys(overviewData.projectIncome)?.length) {
        const updatedProjectIncome = Object.entries(overviewData.projectIncome).reduce(
          (acc, [key, value]) => {
            const updatedKey = key === "Royalty Income" ? "Royalty Interest" : key;
            acc[updatedKey] = value;
            return acc;
          },
          {}
        );

        setProjectedIncomeData(updatedProjectIncome);
      }
      const processedChartDataList = overviewData?.chartData.map(item => {
        if (item.asset_type == "Royalty Income") {
          return {
            ...item,
            "asset_type": "Royalty Interest"
          };
        }
        return item;
      });
      setChartData(processedChartDataList || [])
    } catch (error) {
      console.log('Error while throughing:: ', error);
    } finally {
      setLoading(false);
    }
  };
  const processQueryParams = (filterData, dropdownOptions) => {
    const queryString = window.location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const filterValue = params.get('filter');
      if (filterValue) {
        let decodedValue = decodeURIComponent(filterValue);
        decodedValue = decodedValue.replace(/\+/g, " ");
        let filterInvesmentList = filterData.filter((item) => (decodedValue == item['investment_categories.value']));
        let filterOption = dropdownOptions.filter((item) => (decodedValue == item['value']))

        if (filterOption.length > 0) {
          // setFilteredChartValue(decodedValue);
          setSelectedFilter(filterOption)
          setSelectedInvList(filterInvesmentList)
        } else {
          setChartClick(true)
          console.log("From processQueryParams Else ");
          URLUpdate(false)
        }

      }
    }
  };
  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    const permissionFetch = async () => {
      if (tempPermission) {
        if (!tempPermission.length) {
          setUserPermissions([]);
        } else {
          setUserPermissions(tempPermission);
        }
      }
      if (checkPermission(tempPermission, 'overview') || checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
        fetchInvestmentList()
      } else {
        setLoading(false);
      }
    };
    permissionFetch();
  }

  useEffect(() => {
    //Check Auth permissions and fetch the data 
    authCheckAndFetch();
  }, [])
  useEffect(() => {
    console.log("Search :: ", locationsearch, "OverView Data :: ", overviewDataList, "Char", chartClick);
    if (isBackNavigation) {
      // Skip chartClick check when navigating back
      processFilterApply(overviewDataList, filterOptions)
      setIsBackNavigation(false); // Reset back navigation flag
    }
    else if (!chartClick && overviewDataList && filterOptions && locationsearch) {
      console.log("enterd ::");
      processFilterApply(overviewDataList, filterOptions)
    } else if (!locationsearch) {
      authCheckAndFetch()
    }
    setChartClick(false)
  }, [locationsearch])
  useEffect(() => {
    const handlePopState = () => {
      setIsBackNavigation(true); // Set back navigation flag
    };
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const processFilterApply = (filterData, option) => {
    setChartClick(true)
    const queryString = window.location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const filterValue = params.get('filter');
      if (filterValue) {
        let decodedValue = decodeURIComponent(filterValue);
        decodedValue = decodedValue.replace(/\+/g, " ");
        let filterInvesmentList = filterData.filter((item) => (decodedValue == item['type']));
        console.log("Filter Option :: ", option);

        let filterOption = option.filter((item) => (decodedValue == item['value']))
        console.log("enterd with aury , :: ", decodedValue);

        if (filterInvesmentList.length > 0) {
          console.log("Tets :: filteroption ---> ", filterOption + "Filterinvestment ::: --> ", filterInvesmentList)
          setSelectedFilter(filterOption)
          setSelectedInvList(filterInvesmentList)

        } else {
          setSelectedFilter({ label: 'All', value: 'all' })
          URLUpdate(false)
        }
      }
    } else {
      authCheckAndFetch();
    }

  }

  const handleViewClick = (type, investmentId, categoryId) => {
    const real_estate_master = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;
    const stock_master = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
    const mineral_asset_master = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;
    const personal_asset_master = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;
    const real_estate_holding_master = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;
    if (categoryId == real_estate_master) {
      setLoading(true);
      navigation(`/view-private-equity/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    } else if (categoryId == stock_master) {
      setLoading(true);
      navigation(`/view-liquid-asset/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    } else if (categoryId == mineral_asset_master) {
      setLoading(true);
      navigation(`/view-royalty-income/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    } else if (categoryId == personal_asset_master) {
      setLoading(true);
      navigation(`/view-personal-assets/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    } else if (categoryId == real_estate_holding_master) {
      setLoading(true);
      navigation(`/view-real-estate-holdings/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    }
  };

  const handleFilterChange = (selectedValue, selectedLabel) => {
    setChartClick(true)
    setSelectedFilter({ label: selectedLabel, value: selectedValue });
    if (selectedValue === 'all') {
      URLUpdate(false)
      setSelectedInvList(overviewDataList)
    } else {
      URLUpdate(true, selectedValue)
      let filterInvesmentList = overviewDataList.filter((item) => (selectedValue == item['investment_categories.value']));
      setSelectedInvList(filterInvesmentList)
    }
  }
  const URLUpdate = (Add, value) => {
    setChartClick(true)
    if (Add) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?filter=' + encodeURIComponent(value);
      if (newurl != window.location.href) {
        window.history.pushState({ path: newurl }, '', newurl);
      }
    } else {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }

  const handleChartChange = (selectedValue) => {
    if (selectedValue != '') {
      setLoading(true)
      switch (selectedValue) {
        case 'Liquid Assets':
          navigation('/liquid-asset-list');
          break;
        case 'Private Equity':
          navigation('/private-equity-list');
          break;
        case 'Real Estate Holdings':
          navigation('/real-estate-holding-list');
          break;
        case 'Royalty Income':
          navigation('/royalty-income-list');
          break;
        case 'Personal Assets':
          navigation('/personal-assets-list');
          break;
        case 'Operating Business':
          navigation('/operating-business');
          break;
        case 'Net Worth':
          navigation('/net-worth-list');
          break;
        default:
          setLoading(false);
          break;
      }
    }
  }

  const handleTableChange = (type, value) => {
    try {
      switch (type) {
        case 'print':
          SetEnablePrint(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  // Custom sort function for numeric values
  const numericSort = (a, b, order) => {
    if (order === 'asc') {
      return a - b; // Ascending order
    }
    return b - a; // Descending order
  };

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    // { dataField: 'investment_name', text: 'Investment Name', sort: true, hMinWidth: '15%', hMaxWidth:"15%",  wordWarp: true },
    {
      dataField: 'investment_name', text: 'Investment Name', sort: true, isViewField: true, handleOnClick: handleViewClick, onClickParams: ['id', 'investment_category'],
      headerWidth: '250px', wordWarp: true, action: true, order: 'asc'
    },
    { dataField: 'investment_categories.value', text: 'Asset Class', hMinWidth: '12%', hMaxWidth: "15%", sort: true },
    // { dataField: 'amount_invested', text: 'Amount Invested', sort: true, hMinWidth: '12%', hMaxWidth: "15%", isAmountField: true },
    { dataField: 'cal_current_valuation', text: 'Current Value($)', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isAmountField: true, sortFunc: numericSort },
    // { dataField: 'performance', text: 'Performance', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isPerformanceField: true },
    { dataField: 'createdAt', text: 'Created Date', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isDateField: true },
    { dataField: 'updatedAt', text: 'Updated Date', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isDateField: true },
  ];

  return (<div className="main-content introduction-farm">
    <div className="content-wraper-area">
      <div className="dashboard-area">
        <div className="container-fluid">
          <DataChart
            header={"Portfolio "} data={chartData} labelKey={'asset_type'}
            seriesKey={["total_inv_aft_Debt", "total_invested"]} handleDoubleClick={handleChartChange}
            seriesName={["Current Valuation", "Equity Invested"]} type="bar" overviewPage={true}
          />
          <br />
          <div className='row'>
            <div className='col-sm-12 col-lg-6 col-6'>
              <PieChart
                header={"Net Worth"} data={overviewDataList} labelKey={'investment_categories.value'}
                seriesKey={['current_value', 'amount_borrowed']}
                seriesName={["Current Valuation", "Total Purchase Price"]}
                handleDoubleClick={handleChartChange}
                pieChartType={'net_worth'}
              />
            </div>
            <div className='col-sm-12 col-lg-6 col-6'>
              <PieChart
                header={"Projected Annual Income"} data={projectedIncomeData} labelKey={'investment_categories.value'}
                pieChartType={'project_income'} handleDoubleClick={handleChartChange}
              />
            </div>

          </div>

          <br />
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-title no-print" style={{ marginBottom: '5px' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <h4 style={{ flex: 'auto' }}>Portfolio Summary List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                      <i className="fa fa-print btn btn-circle print-btn" onClick={() => { SetEnablePrint(true); setLoading(true) }} ></i>
                    </OverlayTrigger></h4>
                  </div>
                </div>
                <div >
                  <Datatable data={selectedInvList} columns={columns} csvFileName={'Portfolio Summary List'} headerName={'Portfolio Summary List'} filterOptions={filterOptions} handleFilterChange={handleFilterChange} enablePrint={enablePrint} handleTableChange={handleTableChange} selectedFilter={selectedFilter} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
  </div>);
}
export default Overview;