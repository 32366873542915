import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/commonUtils';
import ReminderList from './ReminderList';
import ReminderTabList from './ReminderTaskList';


const ReminderTab = () => {
  const [selectedTab, setSelectedTab] = useState('reminderlist');
  const [viewMode, setViewMode] = useState(true);
  const handleTabChange = (tabName, event) => {
    event.preventDefault();
    setSelectedTab(tabName);
    setViewMode(true);
  };

  useEffect(() => {
      setSelectedTab('reminderlist');
  }, [])

  return (
    <>
        <div className="main-content ">
          <div className="content-wraper-area">
            <div className="dashboard-area">
              <div className="container-fluid">
                <div className='card'>
                  <div className='card-body'>
                    <ul className="nav nav-tabs no-print">
                      <li className="nav-item" onClick={(e) => handleTabChange('reminderlist', e)}>
                        <a className={`nav-link ${selectedTab === 'reminderlist' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Reminder List</a>
                      </li>
                      <li className="nav-item" onClick={(e) => handleTabChange('tasklist', e)}>
                        <a className={`nav-link ${selectedTab === 'tasklist' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Task List</a>
                      </li>
                    </ul>
                  </div>

                  {selectedTab === 'reminderlist' && (
                    <div >
                      <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                        <div className="content-wraper-area">
                          <div className="dashboard-area">
                            <div className="container-fluid">
                              <div className="col-12">
                                <div className="card">
                                  <div className="card-body tab-data-model">
                                    <div>
                                      <ReminderList />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  { selectedTab === 'tasklist' && (
                    <div >
                      <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                        <div className="content-wraper-area">
                          <div className="dashboard-area">
                            <div className="container-fluid">
                              <div className="col-12">
                                <div className="card">
                                  <div className="card-body tab-data-model">
                                    <div>
                                      <ReminderTabList />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default ReminderTab;