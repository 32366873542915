
import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { PageLoader } from '../../Context/PageLoader';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../../components/Table/Datatable';
import { get, post } from '../../api';
import { GET_ALL_REMINDER_TASK_LIST, UPDATE_REMINDER_STATUS } from '../../api/endpoints'
import { addValueLabel, checkPermission, updateCurrentComponentData } from '../../utils/commonUtils';
import { dateOptions, reminderOptionType, timeOptions, weeklyOptions } from '../../Data/ReminderOptions';
import moment from "moment";

function ReminderTabList() {
    const [reminderList, setReminderList] = useState([]);
    const { loading, setLoading } = useContext(PageLoader);
    const [dataLoaded, setDataLoaded] = useState(false)
    const [userPermission, setUserPermissions] = useState([]);
    const [isMangerOrAdmin, setIsMangerOrAdmin] = useState(false);
    const notify = (message, type) => toast(message, { type });
    const [selectedFilter, setSelectedFilter] = useState({ label: 'All', value: 'All' });
    const [filterOptions, setFilterOptions] = useState("");
    const [originalReminderList, setOriginalReminderList] = useState([]);

    useEffect(() => {
        setLoading(true)
        // authCheckAndFetch();
        fetchData();
    }, [])
    const handleFilterChange = async (selectedValue, selectedLabel) => {
        setLoading(true);

        setSelectedFilter({ label: selectedLabel, value: selectedValue });

        if (selectedValue == 'all') {
            setReminderList(originalReminderList);
            console.log("Original Remider LISt ::: ",originalReminderList);
            
        } else  {
            let filterEntityType =selectedValue == 'estate_planning' ? 'estate_planning' :selectedValue == 'operating_business' ? 'operating_business' :'investment';

            const filteredList = originalReminderList.filter(item => {
                if (filterEntityType == 'investment') {
                    return item.entity_type == filterEntityType && item.investment_value == selectedValue;
                } else {
                    return item.entity_type == filterEntityType;
                }
            });
            setReminderList(filteredList);
        } 
        // else {
        //     const filteredList = originalReminderList.filter(item => {
        //         if (selectedValue) {
        //             return item.investment_value == selectedValue;
        //         }
        //     });
        //     setReminderList(filteredList);
        // }

        setLoading(false);
    };

    const fetchData = async () => {
        const getReminderData = await get(GET_ALL_REMINDER_TASK_LIST);
        const tempUserPermission = await getReminderData?.permissionArr || [];
        setIsMangerOrAdmin((checkPermission(tempUserPermission, 'isAdmin') || checkPermission(tempUserPermission, 'isManager')))
        setUserPermissions(tempUserPermission)
        const InvestmentLabel = {
            "operating_business": 'Operating Business',
            "estate_planning": 'Estate Planning'
        }
        if (getReminderData && getReminderData?.reminderList?.length) {
            const entityTypeToInvestmentType = {
                operating_business: 'operating-business',
                estate_planning: 'estate-planning',
            };
            let tempRemindervalue = getReminderData?.reminderList.map((rem) => {
                let tempInv = rem?.reminder_data?.investment_categories?.investment_type
                rem['reminder_name'] = rem?.reminder_data['reminder_name'];
                rem['email_label'] = rem?.reminder_data?.['email']?.length ? rem.reminder_data['email'].join(', ') : "";
                rem['occurrence_type_label'] = addValueLabel(rem.reminder_data['occurrence_type'], reminderOptionType);
                rem['occurrence_on_label'] = rem.reminder_data['occurrence_type'] == 'onetime' ? moment(rem.reminder_data['occurrence_on']).format("MM/DD/YYYY") :
                    rem['occurrence_type'] == 'yearly' ? moment(rem['occurrence_on']).format("MMM-DD") :
                        rem.reminder_data['occurrence_type'] == "daily" ? 'Every Day' :
                            addValueLabel(rem.reminder_data['occurrence_on'], rem.reminder_data['occurrence_type'] == "weekly" ? weeklyOptions : dateOptions);
                rem['occurrence_time_label'] = addValueLabel(rem.reminder_data['occurrence_time'], timeOptions);
                rem['completedBy'] = rem?.user_reminder_data?.full_name || '';
                // rem['action_permission'] = rem['reminder_task_status']  === 'completed' ? false : checkPermission(tempUserPermission, `${}`)
                rem['action_permission'] = tempUserPermission
                rem['reminder_task_status'] = rem['reminder_task_status'] === 'completed' ? 'Completed' : 'Active';
                rem['entity_type'] = rem?.reminder_data?.entity_type
                rem['investment_type'] = entityTypeToInvestmentType[rem['entity_type']] || tempInv;
                rem['updatedAt'] = rem['reminder_task_status'] === 'Completed' ? rem?.updatedAt : null
                rem['investment_value'] = rem?.reminder_data?.investment_categories?.value || InvestmentLabel[rem.reminder_data.entity_type]
                return rem;
            });
            setOriginalReminderList(tempRemindervalue);
            const dropdownOptionsMap = new Map();
            dropdownOptionsMap.set('all', { label: 'All', value: 'all' });
            tempRemindervalue.forEach(item => {
                const entityType = item.entity_type;
                const value = item.investment_value;

                if (entityType === 'investment' && value !== null) {
                    dropdownOptionsMap.set(value, { label: value, value });
                } else if (entityType === 'operating_business') {
                    dropdownOptionsMap.set('operating_business', { label: 'Operating Business', value: 'operating_business' });
                } else if (entityType === 'estate_planning') {
                    dropdownOptionsMap.set('estate_planning', { label: 'Estate Planning', value: 'estate_planning' });
                } else if (value) {
                    dropdownOptionsMap.set(value, { label: value, value });
                }
            });
            const dropdownOptions = Array.from(dropdownOptionsMap.values());
            console.log('Tempreminder value ::: ', tempRemindervalue)
            console.log("Dropdown options ::: ",dropdownOptions);
            
            setFilterOptions(dropdownOptions);
            setReminderList(tempRemindervalue)
        } else {
            setReminderList([]);
            setOriginalReminderList([]);

        }
        setLoading(false)
        setDataLoaded(true)
    };


    const handleOnClickAction = (type, id) => {
        if (type === 'markAsDone') {
            markAsDone(id);
        }
    };

    const markAsDone = (id) => {
        updateStatus('completed', id);
    };

    const updateStatus = async (status, id) => {
        setLoading(true);
        try {
            let payload = {
                id: id,
                reminder_task_status: status
            };
            const notificationStatus = await post(UPDATE_REMINDER_STATUS, payload);

            if (notificationStatus.status === true) {
                // if (isTabView) handelListUpdate();
                updateCurrentComponentData();
                // handelListUpdate();
                const button = document.getElementById('notification-btn-fetch');
                if (button) {
                    button.click();
                }

                await fetchData();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('Errro message :: ', error)
            notify('Error updating status', 'error');
        }
    };
    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: 'reminder_name', text: 'Schedule Name', sort: true, isViewField: false,
            wordWarp: true
        },
        { dataField: 'investment_value', text: 'Asset Type', sort: true, headerWidth: '15%' },
        { dataField: 'reminder_date', text: 'Due Date', isDateField: true, headerWidth: '15%' },
        { dataField: 'completedBy', text: 'Completed By', headerWidth: '20%' },
        { dataField: 'reminder_task_status', text: 'Status', sort: true },
        { dataField: 'updatedAt', text: 'Completed On', isDateAndTimeField: true, headerWidth: '15%' },

    ];
    columns.push({
        dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, handleOnClick: handleOnClickAction, tooltipSuffixText: " ", onClickParams: ['id', 'investment_name'],
        reminderCount: ['active_reminders_count'],
        disableDelete: true,
        disableEdit: true,
        disableArchive: true,
        showMarkAsDone: true
    })
    console.log('User permissions:: ', isMangerOrAdmin, userPermission)
    return (
        <>
            <br></br>
            <div className='row'>
                <div className='col-6'>
                    <legend className='text-bold'>Reminder Task List</legend>
                </div>
            </div>
            {dataLoaded && <Datatable data={reminderList} columns={columns} csvFileName={'Reminder Task List'} isReminderAdminOrManager={isMangerOrAdmin} isReminderTaskList={true} disableSearch={false}  filterOptions={filterOptions} handleFilterChange={handleFilterChange} selectedFilter={selectedFilter} />}
        </>
    );
}

export default ReminderTabList;